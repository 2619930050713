import React, {Component} from 'react';

import './Device.scss';

class DevicePage extends Component {
  render () {
    return (
      <div>LOAD AND SHOW DAT DEVICE</div>
    );
  }
}

DevicePage.propTypes = {};

export default DevicePage;
