import React from 'react';
import PropTypes from 'prop-types';
import {Query} from 'react-apollo';

class QueryAndRender extends React.Component {
  render () {
    const {
      className,
      query,
      variables,
      process,
      renderLoaded
    } = this.props;

    return (
      <Query
        query={query}
        variables={variables}
      >{
        ({loading, error, data})=> {
          let $body;
          if (loading) {
            $body = this.renderLoading();
          } else if (error) {
            $body = this.renderError();
          } else {
            const processed = process(data);
            $body = renderLoaded(processed);
          }

          return (
            <div className={className}>
              {$body}
            </div>
          );
        }
      }</Query>
    );
  }

  renderLoading () {
    // TODO: proper loading indicator
    return <div>Loading...</div>;
  }

  renderError (error) {
    // TODO: proper error panel
    console.error(error);
    return <div>Error :(</div>;
  }
}

QueryAndRender.propTypes = {
  className: PropTypes.string,
  query: PropTypes.object,
  variables: PropTypes.object,
  process: PropTypes.func,
  renderLoaded: PropTypes.func
};

export default QueryAndRender;
