import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TabBar, Tab} from '@rmwc/tabs';
import {Select} from '@rmwc/select';
import _ from 'lodash';

import {
  Page,
  Enums,
  enumToOptions
} from '@tripp/shared';
import ScheduleRow from './ScheduleRow';
import {getSchedule} from './ExperienceQueries';
import './Experience.scss';

const TABS = ['active', 'unavailable'];

class SchedulePage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      activeTabIndex: 0,
      type: Enums.ExperienceType.Focus,
      platform: Enums.PlatformType.Default,
      contentTier: Enums.ContentTier.Quest2Compatible,
      category: 'None',
      getPrevious: true,
      days: 15
    };
  }

  render () {
    const {type, platform, contentTier, category, getPrevious, days} = this.state;
    const typeOptions = enumToOptions(Enums.ExperienceType);
    const platformOptions = enumToOptions(Enums.PlatformType);
    const categoryOptions = enumToOptions(Enums.CategoryType);
    const contentTierOptions = enumToOptions(Enums.ContentTier);
    return (
      <Page
        className="SchedulePage"
        query={getSchedule}
        variables={{type, contentTier, platform, category, getPrevious, days}}
      >
        {({getSchedule: schedule})=> {
          const $tabs = this.renderTabs(schedule);
          const $body = this.renderBody(schedule);
          return (
            <div className="Schedule">
              <div className="ScheduleExperienceTypeSelect">
                <Select
                  value={type}
                  onChange={(evt)=> {
                    const type = evt.target.value;
                    this.setState({type});
                  }}
                  label="Experience Type"
                  options={typeOptions}
                />
                <Select
                  value={platform}
                  onChange={(evt)=> {
                    const platform = evt.target.value;
                    this.setState({platform});
                  }}
                  label="Platform"
                  options={platformOptions}
                />
                  <Select
                  value={contentTier}
                  onChange={(evt)=> {
                    const contentTier = evt.target.value;
                    this.setState({contentTier});
                  }}
                  label="Content Tier"
                  options={contentTierOptions}
                />
                <Select
                  value={category}
                  onChange={(evt)=> {
                    const category = evt.target.value;
                    this.setState({category});
                  }}
                  label="Category"
                  options={categoryOptions}
                />
              </div>
              <div className="ScheduleTabs">
                {$tabs}
              </div>
              <div className="ScheduleBody">
                {$body}
              </div>
            </div>
          );
        }}
      </Page>
    );
  }

  renderTabs (schedule) {
    const {activeTabIndex} = this.state;

    const $tabs = TABS.map((name)=> {
      let items = schedule[name];
      if (name === 'active') {
        items = items.filter((item)=> !item.backfilled);
      }
      return (
        <Tab key={name}>{name} ({items.length})</Tab>
      );
    });

    return (
      <TabBar
        activeTabIndex={activeTabIndex}
        onActivate={(evt)=> {
          evt.preventDefault();
          const activeTabIndex = evt.detail.index;
          this.setState({activeTabIndex});
        }}
      >
        {$tabs}
      </TabBar>
    );
  }

  renderBody (schedule) {
    const {activeTabIndex} = this.state;
    const key = TABS[activeTabIndex];
    const items = schedule[key];
    const method = `render${_.capitalize(key)}`;
    return this[method](items);
  }

  renderActive (items) {
    const {router} = this.props;
    const $rows = items.map((item, index)=> {
      const {date} = item;
      return (
        <ScheduleRow
          router={router}
          mode="Day"
          key={`${date}_${index}`}
          {...item}
        />
      );
    });

    return (
      <table>
        <thead>
          <tr>
            <th>Date</th>
            <th>Image</th>
            <th>Experience</th>
            <th>Status</th>
            <th>Teaching</th>
            <th>Music</th>
          </tr>
        </thead>
        <tbody>
          {$rows}
        </tbody>
      </table>
    );
  }

  renderUnavailable (unavailable) {
    const {router} = this.props;
    const $rows = unavailable.map((experience)=> {
      const {id} = experience;
      return (
        <ScheduleRow
          router={router}
          mode="Unavailable"
          key={id}
          experience={experience}
        />
      );
    });

    return (
      <table>
        <thead>
          <tr>
            <th>Image</th>
            <th>Experience</th>
            <th>Available On (Deprecated)</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {$rows}
        </tbody>
      </table>
    );
  }
}

SchedulePage.propTypes = {
  router: PropTypes.object.isRequired
};

export default SchedulePage;
