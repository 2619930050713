import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Typography} from '@rmwc/typography';
import {Button} from '@rmwc/button';
import {List, ListItem} from '@rmwc/list';

import {listCatalogEntries} from './CatalogQueries';

import './Catalog.scss';

class CatalogPage extends Component {
  state = {
    loading: true
  };

  async componentDidMount () {
    const {loading} = this.state;
    if (loading) {
      const catalog = await this.getCatalog();
      console.log(catalog);
      this.setState({
        catalog,
        loading: false
      });
    }
  }

  render () {
    const {router} = this.props;
    const {loading} = this.state;
    if (loading) {
      return <h1>Loading</h1>;
    }
    const {catalog} = this.state;
    const entries = catalog.map(({id, localization: {name}})=> {
      return {id, name};
    });

    return (
      <div className="Page CatalogPage">
        <Button onClick={()=> { router.go('/catalog/create'); }}>
          New Catalog Entry
        </Button>
        <br />
        <Typography use="headline6">Catalog</Typography>
        <List>
          {
            entries.map((el, id)=> (
              <ListItem key={id} onClick={()=> { router.go(`/catalog/${el.id}`); }}>
                {el.name}
              </ListItem>
            ))
          }
        </List>
      </div>
    );
  }

  getCatalog = async ()=> {
    const {client} = this.props;
    const variables = {locale: 'en'};

    const result = await client.query({
      query: listCatalogEntries,
      variables
    });

    return result.data.listCatalogEntries;
  }
}

CatalogPage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

export default withApollo(CatalogPage);
