import gql from 'graphql-tag';

const listCatalogEntries = gql`
  query($locale: Locale) {
    listCatalogEntries {
      id
      type
      localization(locale: $locale) {
        locale
        name
        description
        footerText
      }
      image {
        sizes {
          type
          height
          width
          url
        }
      }
    }
  }
`;

const findCatalogEntryByType = gql`
  query ($type: ExperienceType!, $locale: Locale) {
    findCatalogEntryByType (experience: $experience) {
      type
      localization(locale: $locale) {
        locale
        name
        description
        footerText
      }
      imageId
      image {
        sizes {
          type
          height
          width
          url
        }
      }
    }
  }
`;

const getCatalogEntry = gql`
  query ($id: FirestoreID!, $locale: Locale) {
    getCatalogEntry (id: $id) {
      type
      localization(locale: $locale) {
        locale
        name
        description
        footerText
      }
      imageId
      image {
        sizes {
          type
          height
          width
          url
        }
      }
    }
  }
`;

const createCatalogEntry = gql`
  mutation ($input: CatalogEntryInput!, $locale: Locale!) {
    createCatalogEntry(input: $input) {
      id
      type
      localization(locale: $locale) {
        locale
        name
        description
        footerText
      }
      image {
        sizes {
          type
          height
          width
          url
        }
      }
    }
  }
`;

const updateCatalogEntry = gql`
  mutation ($id: FirestoreID!, $input: CatalogEntryUpdateInput!) {
    updateCatalogEntry(id: $id, input: $input) {
      id
    }
  }
`;

const deleteCatalogEntryImage = gql`
  mutation ($id: FirestoreID!) {
    deleteCatalogEntryImage(id: $id) {
      deleted
      deletedAt
    }
  }
`;

export {
  createCatalogEntry,
  deleteCatalogEntryImage,
  findCatalogEntryByType,
  getCatalogEntry,
  listCatalogEntries,
  updateCatalogEntry
};
