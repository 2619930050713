import React, {Component} from 'react';
import {withApollo} from 'react-apollo';
import _ from 'lodash';
import PropTypes from 'prop-types';
import Moment from 'moment';

import {Button} from '@rmwc/button';
import {Typography} from '@rmwc/typography';
import {TextField} from '@rmwc/textfield';
import {Select} from '@rmwc/select';

import {
  ImageUploader,
  DateChooser,
  Enums,
  enumToOptions
} from '@tripp/shared';
import {
  listExperiences,
  updateExperience,
  createExperience,
  getExperience
} from './ExperienceQueries';

const ExperienceMutations = {
  updateExperience,
  createExperience
};

class ExperienceForm extends Component {
  constructor (props) {
    super(props);

    this.uploader = null;
    this.state = {
      experience: {},
      status: Enums.ExperienceStatus.Pending,
      loading: !!props.id,
      jsonChanged: false,
      statusChanged: false,
      availableOn: Moment(),
      src: ''
    };
  }

  render () {
    const {loading, filename, status, availableOn} = this.state;
    const {buttonText} = this.props;
    const statusOptions = enumToOptions(Enums.ExperienceStatus);

    let $loader = '';
    if (!loading) {
      $loader = (
        <div>
          <Select
            value={status}
            onChange={this.handleStatusSelect}
            label="Status"
            options={statusOptions}
          />
          <div className="AvailableOn">
            <Typography
              className="FormTitle"
              use="subtitle1"
            >
              Available On
            </Typography>
            <DateChooser
              date={availableOn}
              ref={(dateChooser)=> {
                this.dateChooser = dateChooser;
              }}
            />
          </div>


          <ImageUploader
            multi={false}
            showUploadButton={false}
            ref={(uploader)=> {
              if (uploader) {
                this.uploader = uploader.wrappedInstance;
              }
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <Typography use="headline4">
          Experience String Form
        </Typography>
        {$loader}
        <div>
          <label>
            Experience JSON:
            <i className="material-icons">file_upload</i>
            <input
              id="file_input_file"
              onChange={this.handleLoadJson}
              className="none"
              type="file"
            />
          </label>
          <TextField label="" value={filename}/>
        </div>
        <Button onClick={this.mutate} raised>{buttonText}</Button>
      </div>
    );
  }

  // TODO: convert this to async/await
  async componentDidMount () {
    if (!this.state.loading) {
      return;
    }

    try {
      const result = await this.queryPreview();
      const experience = result.data.getExperience;
      const {image, status} = experience;
      const src = _.get(image, 'sizes[0].url', null);
      const availableOn = Moment(experience.availableOn, 'YYYY/MM/DD');
      this.setState({
        loading: false,
        status,
        availableOn,
        src
      });
    } catch (err) {
      console.error('Failed to query preview image', err);
    }
  }

  handleStatusSelect = (e)=> {
    const status = e.target.value;
    this.setState({status, statusChanged: true});
  }

  handleLoadJson = (e)=> {
    e.preventDefault();
    const reader = new window.FileReader();
    const file = e.target.files[0];
    const filename = file.name;

    reader.readAsText(file);

    reader.onloadend = ()=> {
      const json = reader.result;
      let experience = JSON.parse(json);

      const bundlePrefixes = [
        'referencedAsset',
        'referencedArmatureAsset'
      ];

      for (const prefix of bundlePrefixes) {
        const namesKey = `${prefix}BundleNames`;
        const bundlesKey = `${prefix}Bundles`;
        experience[namesKey] = experience[bundlesKey].map((el)=> el.name);
        delete experience[bundlesKey];
      }

      const attrsToSkip = [
        'id',
        'version',
        'createdAt',
        'updatedAt',
        'platform',
        'teaching'
      ];

      experience = _.omit(experience, attrsToSkip);

      this.setState({
        experience,
        filename,
        jsonChanged: true
      });
    };
  };

  handleUpdateInputText = (e)=> {
    const filename = e.target.value.replace(/^.*[\\/]/, '');
    this.setState({filename});
  };

  queryPreview = ()=> {
    const {experience} = this.state;
    const {id, client} = this.props;

    const variables = {
      input: experience,
      id
    };

    const result = client.query({
      query: getExperience,
      variables
    });

    return result;
  }

  prepareExperienceInput ({experience, status}) {
    const attrsToSkip = [
      'id',
      'version',
      'createdAt',
      'updatedAt',
      'platform',
      'teaching',
      'image'
    ];
    const availableOn = this.dateChooser.yyyymmdd();
    const result = Object.assign({}, experience, {status, availableOn});
    return _.omit(result, attrsToSkip);
  }

  mutate = async (event)=> {
    event.preventDefault();

    const {
      experience,
      jsonChanged,
      statusChanged,
      status
    } = this.state;

    const {action, client, router} = this.props;
    let {id} = this.props;

    const isCreate = (action === 'createExperience');
    const isUpdate = (action === 'updateExperience');

    if (isCreate && !jsonChanged) {
      window.alert('Experience create requires JSON input'); // eslint-disable-line no-alert
      return;
    }

    let variables;

    try {
      // When this is a create, we need to run the mutation first, because
      // we need to ID to be generated to use with the image uploader
      if (isCreate) {
        const input = this.prepareExperienceInput({experience, status});
        variables = {input};
        console.log('creating experience', variables);
        const result = await client.mutate({
          mutation: ExperienceMutations.createExperience,
          variables
        });

        ({id} = result.data.createExperience);
      }

      variables = {
        id,
        input: {}
      };

      let image = null;
      if (this.uploader.hasFiles()) {
        image = await this.uploader.upload();
      }

      // We run an update if
      // A) Its an update and the json changed
      // B) Its a create or update and there was an image
      let shouldUpdate = false;
      if (isUpdate) {
        if (statusChanged) {
          variables.input.status = status;
          shouldUpdate = true;
        }

        if (this.dateChooser.changed()) {
          variables.input.availableOn = this.dateChooser.yyyymmdd();
          shouldUpdate = true;
        }

        if (jsonChanged) {
          variables.input = this.prepareExperienceInput({experience, status});
          shouldUpdate = true;
        }
      }

      if (image) {
        variables.input.image = image;
        shouldUpdate = true;
      }

      if (shouldUpdate) {
        console.log('updating experience', variables);
        await client.mutate({
          mutation: ExperienceMutations.updateExperience,
          refetchQueries: [{
            query: listExperiences,
            variables: {status}
          }],
          variables
        });
        console.log('updated experience');
      }

      router.go(`/experiences/${id}`);
    } catch (error) {
      console.log(`Failed ${action}`, error);
    }
  }
}

ExperienceForm.propTypes = {
  id: PropTypes.string.isRequired,
  action: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  client: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired
};

export default withApollo(ExperienceForm);
