import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@rmwc/button';
import {Typography} from '@rmwc/typography';

import {session} from '@tripp/shared';

import './Home.scss';


class HomePage extends Component {
  state = {
    messages: [],
    tokenCopied: false
  }

  render () {
    return (
      <div className="Page HomePage">
        <div className="FirebaseToken">
          <div>This is your Firebase token:</div>
          <textarea
            value={session.token}
            readOnly
            ref={(tokenArea)=> {
              this.tokenArea = tokenArea;
            }}
          />
          <Button
            raised
            onClick={this.copyToken}
          >
            Copy Token
          </Button>
          {this.state.tokenCopied && (
            <Typography
              use="subtitle2"
              className="TokenCopiedMessage"
            >
              Token copied to clipboard
            </Typography>
          )}
        </div>
        <div className="Account">
          <div>These are your account details:</div>
          <pre>
            {JSON.stringify(session.account, null, 1)}
          </pre>
        </div>
      </div>
    );
  }

  copyToken = (evt)=> {
    evt.preventDefault();
    this.tokenArea.select();
    document.execCommand('copy');
    this.setState({
      tokenCopied: true
    });
  }
}

HomePage.propTypes = {
  router: PropTypes.object.isRequired
};

export default HomePage;
