import React, { Component } from 'react'
import {withApollo} from 'react-apollo';
import {addContent, getContent, updateContent} from './ContentQueries';
import {TextField} from '@rmwc/textfield';
import {Button} from '@rmwc/button';
import {
  Enums,
  enumToOptions
} from '@tripp/shared';

import PropTypes from 'prop-types';
import {Select} from '@rmwc/select';

const videoFormat = {
  Mono: 'mono',
  Left_Right: 'left-right',
  Top_Bottom: 'top-bottom'

};

const categoryNames = {
  Learn: 'Learn',
  MindfulMoments: 'MindfulMoments',
  Escape: 'Escape',
  Ascend: 'Ascend',
  Meditate: 'Meditate',
  Test: 'Test'
}

class ContentPage extends Component {

  state = {
    categoryId: categoryNames.Ascend,
    categoryName: '',
    videoId: '',
    stereoFormat: Enums.VideoFormat.Mono,
    thumbnail: '',
    url: '',
    description: '',
    title: '',
    locale: '',
    added: new Date().toDateString(),
    updated: new Date().toDateString()
  };

  render () {
    const addContentForm = this.renderAddContentForm();
    return (
      <span>
        {addContentForm}
      </span>
    )
  }

  renderCurrentContent () {

  }

  renderAddContentForm () {
    const {categoryId, videoId, stereoFormat, url, thumbnail, locale, description, title, added} = this.state;
    const videoFormatOptions = enumToOptions(Enums.VideoFormat);
    return (
      <div>
        <div style={{width: '400px', borderStyle: 'solid', color: 'purple', fontWeight: 'lighter', fontFamily: 'Arial, Helvetica, sans-serif', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '20px', textAlign: 'left', fontSize: '15px'}}>
          Add Video Content</div>
        <div style={{float:'center', paddingLeft:'20px', paddingTop:'10px', paddingBottom: '20px'}}>
          <Button style={{fontWeight:'heavy', fontSize:'15px'}} onClick={this.addVideoContent}>
            Add Video
          </Button>
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Video Identifer (eg. CE for Conscious Existence"
            onChange={this.handleFieldChange('videoId')}
            value={this.state.videoId}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Title"
            onChange={this.handleFieldChange('title')}
            value={this.state.title}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Description"
            onChange={this.handleFieldChange('description')}
            value={this.state.description}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Thumbnail"
            onChange={this.handleFieldChange('thumbnail')}
            value={this.state.thumbnail}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Url"
            onChange={this.handleFieldChange('url')}
            value={this.state.url}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Content Added Date"
            onChange={this.handleFieldChange('added')}
            value={this.state.added}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Category Updated Date"
            onChange={this.handleFieldChange('updated')}
            value={this.state.updated}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Locale (format: xx-XX)"
            onChange={this.handleFieldChange('locale')}
            value={this.state.locale}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="ScheduleExperienceTypeSelect">
          <Select
            value={categoryId}
            onChange={(evt)=> {
              const categoryId = evt.target.value;
              this.setState({categoryId});
            }}
            label="Category"
            options={categoryNames}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="ScheduleExperienceTypeSelect">
          <Select
            value={stereoFormat}
            onChange={(evt)=> {
              const stereoFormat = evt.target.value;
              this.setState({stereoFormat});
            }}
            label="Video Format"
            options={videoFormatOptions}
          />
        </div>
      </div>
    )}

  handleFieldChange = (name)=> {
    return (event)=> {
      const {value} = event.target;
      this.setState({[name]: value});
    };
  };

  addVideoContent = async ()=> {
    const {client} = this.props;
    const {categoryId, videoId, stereoFormat, url, thumbnail, locale, description, title, added, updated} = this.state;

    let existingVideoContent = await client.query({
      query: getContent,
      variables: {id: categoryId}
    });

    const existingContentArray = existingVideoContent.data.getContent;
    const sF = videoFormat[stereoFormat];
    const videoIdNoWS= videoId.replace(/\s+/g,'');

    if (existingContentArray && !existingContentArray.find((content)=> content['id'] === categoryId)) {
      // add content as new
      const videoContentInput = {
        input: {
          id: categoryId,
          updatedContent: updated,
          category: {
            names: [
              {
                name: categoryId,
                locale
              }
            ],
            videos: [
              {
                id: videoIdNoWS,
                stereoFormat: sF,
                thumbnail,
                added,
                url,
                featured: 0,
                order: 0,
                descriptions: [
                  {
                    description,
                    locale
                  }
                ],
                titles: [
                  {
                    title,
                    locale
                  }
                ]
              }
            ]
          }
        }
      }

      try {
        const result = await client.mutate({
          mutation: addContent,
          variables: videoContentInput,
          refetchQueries: [
            {
              query: getContent
            }
          ]
        })

        if (result) {
          window.alert('video content added');
        }
      } catch (e) {
        console.log(e)
      }
    } else {
      // update existing content
      const existingContent = existingContentArray.find((content)=> content['id'] === categoryId)
      if (!existingContent.category.videos) existingContent.category.videos = []
      existingContent.category.videos.push(
        {
          id: videoIdNoWS,
          stereoFormat: sF,
          thumbnail,
          added,
          url,
          featured: 0,
          order: 0,
          descriptions: [
            {
              description,
              locale
            }
          ],
          titles: [
            {
              title,
              locale
            }
          ]
        })

      try {
        delete existingContent['createdAt'];
        delete existingContent['updatedAt'];
        const result = await client.mutate({
          mutation: updateContent,
          variables: {
            id: categoryId,
            input: existingContent
          },
          refetchQueries: [
            {
              query: getContent
            }
          ]
        })

        if (result) {
          window.alert('video content updated');
        }
      } catch (e) {
        console.log(e)
      }
    }
  }
}

ContentPage.propTypes = {
  client: PropTypes.object.isRequired,
};

export default withApollo(ContentPage);