import React, {Component} from 'react';
import PropTypes from 'prop-types';

import ExperienceForm from './ExperienceForm';

import './Experience.scss';

class ExperienceEditPage extends Component {
  render () {
    const {router} = this.props;
    return (
      <div className="Page ExperienceEditPage">
        <ExperienceForm
          action="updateExperience"
          buttonText="Update"
          router={router}
          id={this.props.params.id}
        />
      </div>
    );
  }
}

ExperienceEditPage.propTypes = {
  router: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default ExperienceEditPage;
