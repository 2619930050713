import gql from 'graphql-tag';

const listAssetBundles = gql`
  query listAssetBundles ($platform: PlatformType!) {
    listAssetBundles {
      name,
      variants (platform: $platform) {
        hash
        url
      }
    }
  }
`;

const listAssetBundleDocRefs = gql`
  query {
    listAssetBundleDocRefs
  }
`;


export {
  listAssetBundles,
  listAssetBundleDocRefs
};
