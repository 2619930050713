import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Query} from 'react-apollo';
import _ from 'lodash';
import {Button} from '@rmwc/button';
import {Typography} from '@rmwc/typography';
import {Select} from '@rmwc/select';

import {Enums, enumToOptions} from '@tripp/shared';
import {getExperience} from './ExperienceQueries';

import './Experience.scss';

class ExperiencePage extends Component {
  state = {
    platform: Enums.PlatformType.Win64
  };

  render () {
    const {router, params} = this.props;
    const {id} = params;
    const {platform} = this.state;

    return (
      <div className="Page ExperiencePage">
        <Query
          query={getExperience}
          variables={{id, platform}}
        >
          {({loading, error, data})=> {
            if (loading) {
              return <div>Loading...</div>;
            }
            if (error) {
              return <div>Error :(</div>;
            }
            const experience = data.getExperience;
            const {name, id, image, status, availableOn} = experience;
            const url = `/experiences/${id}/edit`;
            const src = _.get(image, 'sizes[0].url', 'https://i.imgur.com/SOIlHxO.jpg');

            const platformOptions = enumToOptions(Enums.PlatformType);

            return (
              <div className="Experience">
                <Typography use="headline6">{name}</Typography>
                <div>
                  <Button onClick={()=> router.go(url)}>
                    Edit
                  </Button>
                </div>
                <div>
                  <Typography use="headline7">Status: {status}</Typography>
                </div>
                <div>
                  <Typography use="headline7">Available On: {availableOn}</Typography>
                </div>
                <img
                  className="preview"
                  src={src}
                  alt="dat experience"
                />
                <Select
                  value={platform}
                  onChange={this.handlePlatformSelect}
                  label="Platform"
                  options={platformOptions}
                />
                <pre>
                  <br />
                  {
                    this.renderMissingAssetBundles({
                      experience,
                      attr: 'referencedAssetBundles'
                    })
                  }
                  <br />
                  {
                    this.renderMissingAssetBundles({
                      experience,
                      attr: 'referencedArmatureAssetBundles'
                    })
                  }
                  <br />
                  {JSON.stringify(experience, null, 1)}
                </pre>
              </div>
            );
          }}
        </Query>
      </div>
    );
  }

  handlePlatformSelect = (evt)=> {
    this.setState({platform: evt.target.value});
  }

  renderMissingAssetBundles = ({experience, attr})=> {
    const bundles = experience[attr];
    const numBundles = bundles.length;
    const missing = bundles.filter((bundle)=> {
      const variant = (bundle.variants || [])[0];
      return !variant;
    });
    const numMissing = missing.length;
    if (numMissing > 0) {
      const $header = (
        <div>
          <strong>{numMissing}/{numBundles}</strong> missing for {attr}
        </div>
      );

      const $missing = missing.map(({name})=> {
        return (
          <div
            key={name}
          >
            {name}
          </div>
        );
      });

      return (
        <div>
          {$header}
          {$missing}
        </div>
      );
    }

    return (
      <div>
        All <strong>{numBundles}</strong> present for {attr}.
      </div>
    );
  }
}

ExperiencePage.propTypes = {
  router: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default ExperiencePage;
