import React from 'react';
import PropTypes from 'prop-types';
import {withApollo, Query} from 'react-apollo';
import _ from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogButton
} from '@rmwc/dialog';
import {Snackbar} from '@rmwc/snackbar';
import {LinearProgress} from '@rmwc/linear-progress';
import {Typography} from '@rmwc/typography';

class Page extends React.Component {
  state = {
    dialog: null,
    snackbar: null
  };

  render () {
    const {
      children: Body,
      className = ''
    } = this.props;
    const queryProps = _.omit(this.props, ['className', 'children']);

    return (
      <div
        className={`Page ${className}`}
      >
        <Query {...queryProps}>
          {({loading, error, data})=> {
            if (loading) {
              return this.renderLoading();
            }
            if (error) {
              return this.renderError(error);
            }

            return (
              <Body
                runQuery={this.runQuery}
                mutate={this.mutate}
                showDialog={this.showDialog}
                showSnackbar={this.showSnackbar}
                {...data}
              />
            );
          }}
        </Query>

        {this.renderDialog()}
        {this.renderSnackbar()}
      </div>
    );
  }

  runQuery = async ({query, variables = {}, name})=> {
    const {client} = this.props;
    const result = await client.query({query, variables});
    return result.data[name];
  };

  mutate = async ({mutation, variables, name})=> {
    const {client} = this.props;
    const result = await client.mutate({mutation, variables});
    return result.data[name];
  };

  renderLoading () {
    return (
      <div className="PageLoading">
        <Typography use="headline6">Loading</Typography>
        <LinearProgress />
      </div>
    );
  }

  renderError (error) {
    console.error(error);
    return (
      <div>
        Error
      </div>
    );
  }

  renderDialog () {
    const {dialog} = this.state;
    if (!dialog) {
      return '';
    }

    const {onClose, title, body} = dialog;

    return (
      <Dialog
        open={true}
        onClose={(evt)=> {
          const {action} = evt.detail;
          this.setState({dialog: null});
          onClose({action});
        }}
      >
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>{body}</DialogContent>
        <DialogActions>
          <DialogButton action="close">Cancel</DialogButton>
          <DialogButton action="accept" isDefaultAction>
            Ok
          </DialogButton>
        </DialogActions>
      </Dialog>
    );
  }

  showDialog ({onAccept, onCancel, title, body}) {
    const dialog = {onAccept, onCancel, title, body};
    this.setState({dialog});
  }

  renderSnackbar () {
    const {snackbar} = this.state;
    if (!snackbar) {
      return '';
    }

    const {message, actionText, actionHandler} = snackbar;
    const defaultActionHandler = ()=> {};

    return (
      <Snackbar
        show={true}
        onHide={()=> this.setState({snackbar: null})}
        message={message}
        actionText={actionText || 'dismiss'}
        actionHandler={actionHandler || defaultActionHandler}
        alignStart
      />
    );
  }

  showSnackbar ({message, actionText, actionHandler}) {
    const snackbar = {message, actionText, actionHandler};
    this.setState({snackbar});
  }
}

Page.propTypes = {
  children: PropTypes.func.isRequired,
  query: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  variables: PropTypes.object,
  className: PropTypes.string
};

export default withApollo(Page);
