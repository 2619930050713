import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import _ from 'lodash';

const configAttrs = [
  'apiKey',
  'authDomain',
  'databaseURL',
  'projectId',
  'storageBucket'
];

const config = {};
for (const attr of configAttrs) {
  const envVarSuffix = _.snakeCase(attr).toUpperCase();
  const envVar = `REACT_APP_FIREBASE_${envVarSuffix}`;
  if (!(envVar in process.env)) {
    throw new Error(`Missing environment var ${envVar}`);
  }
  config[attr] = process.env[envVar];
}

firebase.initializeApp(config);

export const firestore = firebase.firestore();
const settings = {};
firestore.settings(settings);

export default firebase;
