import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';
import _ from 'lodash';
import {Button} from '@rmwc/button';

import {
  DateChooser,
  AsyncButton
} from '@tripp/shared';
import {listExperiencesSchedule, updateScheduledExperience, getSchedule} from './ExperienceQueries';

import './Experience.scss';

class ExperienceScheduleRow extends Component {
  state = {
    editing: false
  };

  render () {
    const {
      id,
      name,
      availableOn,
      image
    } = this.props.experience;

    const src = _.get(image, 'sizes[0].url', '');

    return (
      <tr className="ExperienceDay" key={id}>
        <td className="ExperienceAvailableOn">
          {availableOn}
        </td>
        <td className="ExperienceImage">
          <img
            src={src}
            alt={`screenshot of ${name}`}
          />
        </td>
        <td className="ExperienceName">
          {name}
        </td>
        <td className="ExperienceEditAvailable">
          {this.renderEditAvailable()}
        </td>
        <td className="ExperienceEdit">
          <Button onClick={this.gotoEdit}>
            Edit
          </Button>
        </td>
      </tr>
    );
  }

  renderEditAvailable () {
    const {scheduledDate} = this.props.experience;
    const {editing} = this.state;

    if (!editing) {
      return (
        <Button onClick={this.editScheduledDate}>
          Change availability
        </Button>
      );
    }

    return (
      <div>
        <DateChooser
          date={scheduledDate}
          ref={(dateChooser)=> {
            this.dateChooser = dateChooser;
          }}
        />
        <AsyncButton
          action={this.updateScheduledDate}
        >
          Update
        </AsyncButton>
      </div>
    );
  }

  gotoEdit = (event)=> {
    event.preventDefault();
    const {experience, router} = this.props;
    const {id} = experience;
    const url = `/experiences/${id}/edit`;
    router.go(url);
  };

  editScheduledDate = (event)=> {
    event.preventDefault();
    this.setState({editing: true});
  };

  updateScheduledDate = async ()=> {
    const {client, experience} = this.props;
    const scheduledDate = this.dateChooser.yyyymmdd();
    await client.mutate({
      mutation: updateScheduledExperience,
      variables: {
        currentDate: experience.availableOn,
        newDate: scheduledDate,
        type: experience.type
      },
      refetchQueries: [
        {
          query: getSchedule
        }
      ]
    });
    this.setState({editing: false});
  };
}

ExperienceScheduleRow.propTypes = {
  client: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  experience: PropTypes.shape({
    id: PropTypes.string.isRequired,
    availableOn: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    image: PropTypes.object
  }).isRequired
};

export default withApollo(ExperienceScheduleRow);
