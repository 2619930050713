import gql from 'graphql-tag';

const assetBundleFragment = `
  name
  variants (platform: $platform) {
    hash
  }
`;

const assetBundlesFragment = `
  referencedAssetBundleNames
  referencedArmatureAssetBundleNames
  referencedAssetBundles {
    ${assetBundleFragment}
  }
  referencedArmatureAssetBundles {
    ${assetBundleFragment}
  }
`;

const imageSizesFragment = gql`
    fragment imageSizesFragment on Image {
        sizes {
            url
            width
            height
        }
    }
`;

const experienceListFragment = gql`
    ${imageSizesFragment}
    fragment experienceListFragment on Experience {
        id
        name
        type
        status
        platform
        image {
            ...imageSizesFragment
        }
    }
`;

const listExperiences = gql`
    ${experienceListFragment}
    query listExperiencesAdmin ($status: ExperienceStatus!) {
        listExperiencesAdmin (status: $status) {
            ...experienceListFragment
        }
    }
`;

const listExperiencesSchedule = gql`
    ${experienceListFragment}
    query listExperiencesSchedule {
        listExperiencesAdmin (
            status: Published,
            orderBy: {
                field: "availableOn",
                dir: desc
            }
        ) {
            ...experienceListFragment
        }
    }
`;

const getSchedule = gql`
    ${experienceListFragment}
    query getSchedule ($type: ExperienceType!, $contentTier: ContentTier, $platform: PlatformType!, $category: String, $getPrevious: Boolean, $days: Int) {
        getSchedule (type: $type, contentTier: $contentTier, platform: $platform, category: $category, getPrevious: $getPrevious, days: $days)  {
            active {
                date
                backfilled
                experience {
                    ...experienceListFragment
                }
                teaching {
                    id
                    name
                }
                musicStem {
                    id
                    name
                }
            }
            unavailable {
                ...experienceListFragment
            }
        }
    }
`;

const deleteExperience = gql`
    mutation deleteExperience($id: FirestoreID!) {
        deleteExperience(id: $id) {
            deletedAt
            deleted
        }
    }
`;

const createExperience = gql`
    mutation createExperience($input: ExperienceInput!) {
        createExperience(input: $input) {
            id
            name
        }
    }
`;

const updateExperience = gql`
    mutation updateExperience($id: FirestoreID!, $input: ExperienceInput!) {
        updateExperience(id: $id, input: $input) {
            name
        }
    }
`;

const updateScheduledExperience = gql`
    mutation updateScheduledExperience ($currentDate: Date!, $newDate: Date!, $type: ExperienceType!) {
        updateScheduledExperience(currentDate: $currentDate, newDate: $newDate, type: $type) {
            scheduledDate
        }
    }
`;

const uploadScheduledExperiences = gql`
    mutation uploadScheduledExperiences ($inputs: [ScheduledExperienceInput!]!) {
        uploadScheduledExperiences(inputs: $inputs) {
            id
        }
    }`

const getExperience = gql`
    ${imageSizesFragment}
    query ($id: FirestoreID!, $platform: PlatformType) {
        getExperience (id: $id) {
            id
            status
            name
            type
            displayName
            displayDescription
            startingStageName
            musicController
            availableOn
            ${assetBundlesFragment}
            teaching {
                id
                name
                deprivationArmature
                tunnelArmature
                closeArmature
                createdAt
                updatedAt
                ${assetBundlesFragment}
            }
            nonrandomArmatures
            splineArmatures
            planeArmatures
            splinePlaneArmatures
            infinteArmatures
            obfuscatingArmature
            tankStatuesArmature
            otherArmatures
            otherObjectArmatures
            gameArmature
            ascensionArmatures
            specificArmatures
            planeObjectArmatures
            standaloneObjectArmatures
            xml
            image {
                extension
                ...imageSizesFragment
            }
        }
    }
`;

export {
  listExperiences,
  listExperiencesSchedule,
  deleteExperience,
  createExperience,
  updateScheduledExperience,
  uploadScheduledExperiences,
  updateExperience,
  getExperience,
  getSchedule
};
