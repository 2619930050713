import Pusher from 'pusher-js';

function pusherSubscribe ({event, channel, callback}) {
  const {
    REACT_APP_TRIPP_ENV: TRIPP_ENV,
    REACT_APP_PUSHER_KEY: PUSHER_KEY,
    REACT_APP_PUSHER_CLUSTER: PUSHER_CLUSTER
  } = process.env;

  const pusherConfig = {
    cluster: PUSHER_CLUSTER
  };

  if (TRIPP_ENV === 'dev') {
    Pusher.logToConsole = true;
    pusherConfig.forceTLS = true;
  }

  const pusher = new Pusher(PUSHER_KEY, pusherConfig);
  channel = pusher.subscribe(channel);
  channel.bind(event, callback);
}

export default pusherSubscribe;
