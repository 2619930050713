import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Typography} from '@rmwc/typography';
import {Button} from '@rmwc/button';
import {Elevation} from '@rmwc/elevation';
import {List, ListItem} from '@rmwc/list';

import {getMultiUseLicense} from './MultiUseQueries';

import './MultiUse.scss';

class MultiUsePage extends Component {
  state = {
    loading: true
  };

  async componentDidMount () {
    const {loading} = this.state;
    if (loading) {
      const {id} = this.props.params;
      const multiUseLicense = await this.getMultiUseLicense(id);
      this.setState({
        multiUseLicense,
        loading: false
      });
    }
  }

  render () {
    const {router, params} = this.props;
    const {loading} = this.state;
    if (loading) {
      return <h1>Loading</h1>;
    }
    const {
      name,
      email,
      phoneNumber,
      companyName,
      companyType,
      companyRole,
      numOfLicenses

    } = this.state.multiUseLicense;
    return (
      <div className="Page CompanyPage Centered">
        <Elevation z={3} className="Centered">
          <Button onClick={()=> { router.go('/multiUseLicenses'); }}>
            back
          </Button>
          {/*<Button onClick={()=> { router.go(`/companies/${params.id}/edit`); }}>*/}
          {/*  edit*/}
          {/*</Button>*/}
          <br />
          <Typography use="headline3">{name}</Typography>
          <br />
          <b>Name: </b>{name}<br />
          <b>Email: </b>{email}<br />
          <b>Phone Number: </b>{phoneNumber}<br />
          <b>Company Name: </b>{companyName}<br />
          <b>Company Type: </b>{companyType}<br />
          <b>Company Role: </b>{companyRole}<br />
          <b>Number of Licenses: </b>{numOfLicenses}<br />
        </Elevation>
      </div>
    );
  }

  getMultiUseLicense = async ()=> {
    const {client} = this.props;
    const {id} = this.props.params;
    const variables = {id};

    const result = await client.query({
      query: getMultiUseLicense,
      variables
    });

    return result.data.getMultiUseLicense();
  }
}

MultiUsePage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default withApollo(MultiUsePage);
