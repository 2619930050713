import gql from 'graphql-tag';

const listMultiUseLicenses = gql`
  query {
    listMultiUseLicenses {
      name
    }
  }
`;

const getMultiUseLicense = gql`
  query getMultiUseLicense ($id: FirestoreID!) {
    getMultiUseLicense(id: $id) {
      name
    }
  }
`;

const createMultiUseLicense = gql`
  mutation createMultiUseLicense($input: MultiUseLicenseInput!) {
    createMultiUseLicense (input: $input) {
      name
      email
      phoneNumber
      companyName
      companyRole
      companyType
    }
  }
`;

const updateMultiUseLicense = gql`
  mutation updateMultiUseLicense ($input: MultiUseLicenseInput!, $id: FirestoreID!) {
    updateMultiUseLicense(id: $id, input: $input) {
      id
    }
  }
`;

const deleteMultiUseLicense = gql`
  mutation deleteMultiUseLicense ($id: FirestoreID!) {
    deleteMultiUseLicense(id: $id) {
      deletedAt
      deleted
    }
  }
`;

export {
  listMultiUseLicenses,
  getMultiUseLicense,
  createMultiUseLicense,
  updateMultiUseLicense,
  deleteMultiUseLicense
};
