import React, {Component} from 'react';

import './NotFound.scss';

class NotFoundPage extends Component {
  render () {
    return (
      <h1>404</h1>
    );
  }
}

export default NotFoundPage;
