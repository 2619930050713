import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {Page} from '@tripp/shared';
import {listExperiencesSchedule} from './ExperienceQueries';
import ExperienceScheduleRow from './ExperienceScheduleRow';
import './Experience.scss';

class ExperiencesSchedulePage extends Component {
  render () {
    // TODO: add withRouter halper
    const {router} = this.props;

    return (
      <Page
        className="ExperiencesSchedulePage"
        query={listExperiencesSchedule}
      >
        {({listExperiences: experiences})=> {
          if (!experiences.length) {
            return 'No experiences';
          }

          const $items = experiences.map((experience)=> {
            return (
              <ExperienceScheduleRow
                key={experience.id}
                router={router}
                experience={experience}
              />
            );
          });

          return (
            <table>
              <tbody>
                {$items}
              </tbody>
            </table>
          );
        }}
      </Page>
    );
  }
}

ExperiencesSchedulePage.propTypes = {
  router: PropTypes.object.isRequired
};

export default ExperiencesSchedulePage;
