import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Papa from 'papaparse';
import {withApollo} from 'react-apollo';
import {uploadScheduledExperiences} from './ExperienceQueries';
import {
  AsyncButton
} from '@tripp/shared';

import './Experience.scss';

class ScheduleUploaderPage extends Component {

  constructor() {
    super();
    this.state = {
      csvfile: undefined
    };
    this.updateData = this.updateData.bind(this);
  }

  handleChange = event => {
    this.setState({
      csvfile: event.target.files[0]
    });
  };

  importCSV = async () => {
    const { csvfile } = this.state;
    Papa.parse(csvfile, {
      complete: this.updateData,
      header: true
    });
  };

  async updateData(result) {
    const data = result.data;
    const {client} = this.props;
    let alertMsg = 'Upload Successful. Check the Schedule Page. **You may need to refresh your browser**';
    try {
      await client.mutate({
        mutation: uploadScheduledExperiences,
        variables: {
          inputs: data
        }
      });
    } catch (e) {
      alertMsg = e.message;
      window.location.reload();
    }
    window.alert(alertMsg);
  };

  render() {
    console.log(this.state.csvfile);
    return (
      <div className="App">
        <h2>Experience Schedule Uploader</h2>
        <input
          className="csv-input"
          type="file"
          ref={input => {
            this.filesInput = input;
          }}
          name="file"
          placeholder={null}
          onChange={this.handleChange}
        />
        <p />
        <AsyncButton action={this.importCSV}> Upload</AsyncButton>
      </div>
    );
  }
}

ScheduleUploaderPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default withApollo(ScheduleUploaderPage);