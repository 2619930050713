import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Typography} from '@rmwc/typography';
import {Button} from '@rmwc/button';
import {TextField} from '@rmwc/textfield';
import {Elevation} from '@rmwc/elevation';
import {List, ListItem} from '@rmwc/list';
import {Fab} from '@rmwc/fab';

import {createMultiUseLicense, listMultiUseLicenses} from './MultiUseQueries';

import './MultiUse.scss';

class MultiUseCreatePage extends Component {
  state = {
    company: {
      name: '',
      contact: '',
      adminDomains: []
    }
  }

  render () {
    const {router, params} = this.props;
    const {
      name,
      contact,
      adminDomains
    } = this.state.company;
    return (
      <div className="Page MultiUsePage Centered">
        <Elevation z={3} className="Centered">
          <Button onClick={()=> { router.go(`/companies/${params.id}`); }}>
            back
          </Button>
          <div>
            <TextField
              label='Company Name'
              value={name}
              onChange={this.setName}
            />
          </div>

          <div>
            <TextField
              label='Contact'
              value={contact}
              onChange={this.setContact}
            />
          </div>

          <div>
            <Typography use='subtitle1'>
              Admin Domains
              <Fab mini onClick={this.addDomain}>add</Fab>
            </Typography>

            <List>
              {adminDomains.map((el, id)=> (
                <ListItem key={id}>
                  <AdminDomainField
                    field={el}
                    setDomain={this.setDomain(id)}
                    deleteDomain={this.deleteDomain(id)}
                  />
                </ListItem>
              ))
              }
            </List>
          </div>

          <div>
            <Button onClick={this.createMultiUseLicense}>
              submit
            </Button>
          </div>
        </Elevation>
      </div>
    );
  }

  newWorkerToggle = ()=> {
    const {newWorker} = this.state;
    this.setState({newWorker: !newWorker});
  }

  addDomain = ()=> {
    const {company} = this.state;
    company.adminDomains.push('');
    this.setState({company});
  }

  setDomain = (index)=> {
    return (e)=> {
      if (e.preventDefault) {
        e.preventDefault();
      }
      const {value} = e.target;
      const {company} = this.state;
      company.adminDomains[index] = value;
      this.setState({company});
    };
  }

  deleteDomain = (index)=> {
    return ()=> {
      const {company} = this.state;
      const domains = company.adminDomains.filter((el, idx)=> idx !== index);
      company.adminDomains = domains;
      this.setState({company});
    };
  }

  setName = (e)=> {
    const company = {...this.state.company};
    const name = e.target.value;
    company.name = name;
    this.setState({company});
  }

  setContact = (e)=> {
    const company = {...this.state.company};
    const contact = e.target.value;
    company.contact = contact;
    this.setState({company});
  }

  createMultiUseLicense = async ()=> {
    const {client, router} = this.props;
    const input = this.state.company;
    const variables = {input};
    const mutation = createMultiUseLicense;
    try {
      const result = await client.mutate({
        mutation,
        variables,
        refetchQueries: [{query: listMultiUseLicenses}]
      });
      console.log(result);
      const {id} = result.data.createMultiUseLicense;
      return router.go(`/companies/${id}`);
    } catch (error) {
      console.error('Error updating company', error);
      throw error;
    }
  }
}

class AdminDomainField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      edit: !!props.field
    };
  }

  render () {
    const {edit} = this.state;
    if (!edit) {
      return (
        <div>
          {this.props.field}
          <Fab mini onClick={this.toggleEdit}>edit</Fab>
          <Fab mini onClick={this.props.deleteDomain}>delete</Fab>
        </div>
      );
    }
    return (
      <div>
        <TextField label="adminDomain"
          value={this.props.field}
          onChange={this.props.setDomain}
        />
        <Fab mini onClick={this.handleSubmit}>check</Fab>
        <Fab mini onClick={this.props.deleteDomain}>close</Fab>
      </div>
    );
  }

  toggleEdit = ()=> {
    const {edit} = this.state;
    this.setState({edit: !edit});
  }

  handleSubmit = ()=> {
    this.toggleEdit();
  }
}

MultiUseCreatePage.propTypes = {
  router: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  }),
  client: PropTypes.object.isRequired
};

export default withApollo(MultiUseCreatePage);
