import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@rmwc/textfield';
import {Button} from '@rmwc/button';
import {Typography} from '@rmwc/typography';
import emailRegex from 'email-regex';

import './Authentication.scss';

const Mode = {
  SignIn: 'SignIn',
  SignUp: 'SignUp'
};

class AuthenticationForm extends Component {
  constructor (props) {
    super(props);
    const {mode = Mode.SignIn} = props;
    this.state = {
      mode,
      error: '',
      email: '',
      password: '',
      passwordConfirmation: ''
    };
  }

  componentWillReceiveProps (nextProps) {
    const {mode} = nextProps;
    if (this.state.mode !== mode) {
      this.setState({mode});
    }
  }


  render () {
    const {error} = this.state;

    const $fields = this.renderFields();
    const $buttons = this.renderButtons();

    // TODO: better error integration with form fields etc.
    return (
      <div className="Authentication">
        {$fields}

        <div>
          { error }
        </div>

        {$buttons}
      </div>
    );
  }

  renderFields () {
    const {email, password, passwordConfirmation} = this.state;

    let $confirm = '';
    if (this.isSignUp()) {
      $confirm = (
        <div className="FormField">
          <TextField
            label="Confirm Password"
            type="password"
            onChange={this.handleFieldChange('passwordConfirmation')}
            value={passwordConfirmation}
          />
        </div>
      );
    }

    return (
      <div>
        <div className="FormField">
          <TextField
            label="Email"
            onChange={this.handleFieldChange('email')}
            value={email}
          />
        </div>
        <div className="FormField">
          <TextField
            label="Password"
            type="password"
            onChange={this.handleFieldChange('password')}
            value={password}
          />
        </div>
        {$confirm}
      </div>
    );
  }

  renderButtons () {
    const {allowSignup} = this.props;
    const {mode} = this.state;

    const SwitchModeButton = ({prompt, onClick, newMode})=> {
      return (
        <div className="Goto">
          <Typography use="subtitle2">{prompt}</Typography>
          <Button dense onClick={onClick}>
            {newMode} here
          </Button>
        </div>
      );
    };

    let $switchModeButton = '';
    if (this.isSignIn() && allowSignup) {
      $switchModeButton = (
        <SwitchModeButton
          prompt="Need an account?"
          onClick={this.gotoSignUp}
          newMode="signup"
        />
      );
    }
    if (this.isSignUp()) {
      $switchModeButton = (
        <SwitchModeButton
          prompt="Already have an account?"
          onClick={this.gotoSignIn}
          newMode="signin"
        />
      );
    }

    const $buttons = [
      {
        provider: 'Email',
        icon: 'fas fa-envelope',
        unelevated: true
      },
      {
        provider: 'Google',
        icon: 'fab fa-google',
        outlined: true
      }
      // {
      //   provider: 'Facebook',
      //   icon: 'fab fa-facebook',
      //   outlined: true
      // }
    ].map(({provider, icon, outlined, unelevated})=> (
      <Button
        key={provider}
        unelevated={unelevated}
        outlined={outlined}
        onClick={(event)=> {
          event.preventDefault();
          this.onAuthButtonClick({provider});
        }}
      >
        <i className={`left ${icon}`} />
        <span>{mode}</span>
      </Button>
    ));

    return (
      <div className="AuthenticationButtons">
        {$buttons}
        {$switchModeButton}
      </div>
    );
  }

  isSignUp () {
    const {mode} = this.state;
    return (mode === Mode.SignUp);
  }

  isSignIn () {
    const {mode} = this.state;
    return (mode === Mode.SignIn);
  }

  gotoSignIn = ()=> {
    if (this.props.redirect) {
      const {router} = this.props;
      router.go('/signin');
    } else {
      this.setState({mode: Mode.SignIn});
    }
  }

  gotoSignUp = ()=> {
    if (this.props.redirect) {
      const {router} = this.props;
      router.go('/signup');
    } else {
      this.setState({mode: Mode.SignUp});
    }
  }

  handleFieldChange = (name)=> {
    return (event)=> {
      const {value} = event.target;
      this.setState({[name]: value});
    };
  };

  onAuthButtonClick = ({provider})=> {
    if (provider === 'Email') {
      const {email, password} = this.state;
      const {mode} = this.props;

      if (!this.validateEmail()) {
        this.setState({error: 'Invalid email'});
        return;
      }

      if (this.isSignUp() && !this.validatePassword()) {
        this.setState({error: 'Passwords do not match'});
        return;
      }

      this.authenticate({email, password, mode});
    } else {
      this.authenticate({provider});
    }
  }

  validateEmail = ()=> {
    const {email} = this.state;
    const re = emailRegex({exact: true});
    return re.test(email);
  };

  validatePassword = ()=> {
    // TODO: validate password format
    const {password, passwordConfirmation} = this.state;
    return (password === passwordConfirmation);
  }

  async authenticate (params) {
    const {router, session} = this.props;

    try {
      await session.authenticate(params);
      router.go('/');
    } catch (error) {
      let message;
      const {code} = error;
      if (code) {
        // TODO: add oauth errors
        message = {
          'auth/invalid-email': 'Invalid email address',
          'auth/user-disabled': 'User is disabled',
          'auth/user-not-found': 'Invalid credentials',
          'auth/wrong-password': 'Invalid credentials'
        }[code];
      }
      if (!message) {
        console.error(error.message);
        message = 'Error signing in';
      }
      this.setState({error: message});
    }
  }
}

AuthenticationForm.Mode = Mode;

AuthenticationForm.propTypes = {
  router: PropTypes.object.isRequired,
  allowSignup: PropTypes.bool.isRequired,
  mode: PropTypes.string,
  session: PropTypes.object.isRequired,
  redirect: PropTypes.bool.isRequired
};

export default AuthenticationForm;
