import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@rmwc/button';
import {LinearProgress} from '@rmwc/linear-progress';
import {Typography} from '@rmwc/typography';
import uuidv4 from 'uuid/v4';
import Promise from 'bluebird';
import {withApollo} from 'react-apollo';
import _ from 'lodash';

import ImageUploaderPlaceholder from './ImageUploaderPlaceholder';
import './ImageUploader.scss';

function preventDefaults (e) {
  e.preventDefault();
  e.stopPropagation();
}

class ImageUploader extends Component {
  constructor (props) {
    super(props);
    this.dropAreaId = uuidv4();
    this.state = {
      files: [],
      uploading: false,
      hovering: false
    };
    this.uploaders = [];
  }

  render () {
    const {hovering} = this.state;
    const {multi} = this.props;

    const noun = this.noun();

    const primaryClassName = 'ImageUploaderDropArea';
    const multiClassName = `${primaryClassName}${multi ? 'Multi' : 'Single'}`;
    const dropAreaClassNames = [primaryClassName, multiClassName];

    let $body;
    if (this.hasFiles()) {
      $body = this.renderUploaders();
    } else {
      $body = (
        <div className="ImageUploaderPrompt">
          <Typography use="subtitle1">Drop {noun} here</Typography>
        </div>
      );
    }

    if (hovering) {
      dropAreaClassNames.push('Hovering');
    }

    return (
      <div className="ImageUploader">
        <form
          id={this.dropAreaId}
          className={dropAreaClassNames.join(' ')}
        >
          {$body}

          <input
            type="file"
            multiple
            accept="image/*"
            onChange={this.onFilesSelected}
            ref={(input)=> {
              this.fileInput = input;
            }}
          />

          {this.renderProgress()}

          {this.renderButtons()}
        </form>
      </div>
    );
  }

  renderProgress () {
    const {updating, mutating} = this.state;

    let progress = '';
    if (updating || mutating) {
      let text;
      const options = {};
      if (mutating) {
        text = 'Updating';
      } else {
        text = 'Uploading images';
        options.progress = this.uploadProgress();
        console.log('current total upload progress', options.progress);
      }

      progress = (
        <div>
          <Typography use="subtitle2">{text}</Typography>
          <LinearProgress {...options}></LinearProgress>
        </div>
      );
    }

    return progress;
  }

  renderUploaders () {
    const {files} = this.state;
    const {bucket} = this.props;
    const uploaders = [];

    // could try file.name, but what if not unique?
    const $uploaders = files.map((file, index)=> {
      return (
        <ImageUploaderPlaceholder
          key={index}
          file={file}
          bucket={bucket}
          ref={(ref)=> {
            // ignore the calls with ref === null
            if (ref) {
              uploaders.push(ref);
            }
          }}
          onRemove={()=> {
            let {files} = this.state;
            files = files.filter((file, i)=> (i !== index));
            this.setState({files});
          }}
        />
      );
    });

    this.uploaders = uploaders;

    return (
      <div className="ImageUploaderUploaders">
        {$uploaders}
      </div>
    );
  }

  renderButtons () {
    if (!this.canAddFiles()) {
      return '';
    }

    const noun = this.noun();
    const {showUploadButton} = this.props;
    const {uploading} = this.state;

    let $uploadButton = '';
    if (showUploadButton) {
      $uploadButton = (
        <Button
          raised
          className="ImageUploaderUploadFiles"
          disabled={uploading || !this.uploadEnabled()}
          onClick={(event)=> {
            event.preventDefault();
            this.upload();
          }}
        >
          Upload
        </Button>
      );
    }

    return (
      <div className="ImageUploaderButtons">
        <Button
          raised
          className="ImageUploaderChooseFiles"
          disabled={uploading}
          onClick={(event)=> {
            event.preventDefault();
            this.fileInput.click();
          }}
        >
          Choose {_.capitalize(noun)}
        </Button>

        {$uploadButton}
      </div>
    );
  }

  noun () {
    // TODO: allow for prop?
    const {multi} = this.props;
    return multi ? 'images' : 'image';
  }

  canAddFiles () {
    const {multi} = this.props;
    if (multi) {
      return true;
    }
    return !this.hasFiles();
  }

  hasFiles () {
    const {files} = this.state;
    return (files.length > 0);
  }

  uploadEnabled () {
    if (!this.hasFiles()) {
      return false;
    }
    const {uploaders} = this;
    return uploaders.every((u)=> u.loaded());
  }

  uploadProgress () {
    let total = 0;
    const {uploaders} = this;
    const {length} = uploaders;
    if (length === 0) {
      return 0;
    }
    for (const u of uploaders) {
      total += u.uploadProgress();
    }
    return (total / length);
  }

  componentDidMount () {
    const {document} = window;
    const drop = document.getElementById(this.dropAreaId);

    const events = {
      dragenter: this.hover,
      dragover: this.hover,
      dragleave: this.unhover,
      drop: this.unhover
    };

    for (const [event, handler] of Object.entries(events)) {
      // remove default dnd behavior
      for (const elem of [drop, document.body]) {
        elem.addEventListener(event, preventDefaults, false);
      }
      drop.addEventListener(event, handler, false);
    }
    drop.addEventListener('drop', this.onFilesDropped, false);
  }

  hover = ()=> {
    this.setState({hovering: true});
  }

  unhover = ()=> {
    this.setState({hovering: false});
  }

  onFilesDropped = (event)=> {
    const {files} = event.dataTransfer;
    this.prepareFiles(files);
  }

  onFilesSelected = (event)=> {
    const {files} = event.target;
    this.prepareFiles(files);
  }

  prepareFiles (inputs) {
    const {files} = this.state;
    for (const file of inputs) {
      files.push(file);
    }
    this.setState({files});
  }

  upload = async ()=> {
    const {multi} = this.props;
    const {uploaders} = this;
    this.setState({uploading: true});
    const inputs = await Promise.map(uploaders, (u)=> u.upload());
    const {mutation, client, refetchQueries} = this.props;
    const getVariables = this.props.variables;

    const result = multi ? inputs : inputs[0];

    this.setState({uploading: false});

    if (mutation) {
      this.setState({
        uploading: false,
        mutating: true
      });

      const variables = getVariables(result);
      await client.mutate({mutation, variables, refetchQueries});

      this.setState({
        mutating: false,
        files: []
      });
    }

    return result;
  };
}

ImageUploader.propTypes = {
  file: PropTypes.object,
  bucket: PropTypes.string,
  variables: PropTypes.func,
  multi: PropTypes.bool,
  showUploadButton: PropTypes.bool,
  mutation: PropTypes.object,
  client: PropTypes.object,
  refetchQueries: PropTypes.array
};

ImageUploader.defaultProps = {
  multi: true,
  showUploadButton: true
};

export default withApollo(ImageUploader, {withRef: true});
