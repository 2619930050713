import gql from 'graphql-tag';

const addContent = gql`
    mutation addContent ($input: ContentCategoryInput!) {
        addContent (input: $input)
    }
`;

const updateContent = gql`
    mutation updateContent ($input: ContentCategoryUpdateInput!) {
        updateContent (input: $input)
    }
`;

const getContent = gql`
    query getContent {
        getContent
    }
`;

export {
  addContent,
  updateContent,
  getContent
};