import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Elevation} from '@rmwc/elevation';

import {session} from '../Session';
import AuthenticationForm from './AuthenticationForm';
import './Authentication.scss';

class AuthenticationCentered extends Component {
  render () {
    const {router, allowSignup, mode} = this.props;

    return (
      <Elevation z={3} className="Centered">
        <img alt="logo" src="/LogoSmall.png"/>
        <AuthenticationForm
          session={session}
          router={router}
          mode={mode}
          allowSignup={allowSignup}
          redirect={true}
        />
      </Elevation>
    );
  }
}

AuthenticationCentered.propTypes = {
  router: PropTypes.object.isRequired,
  allowSignup: PropTypes.bool.isRequired,
  mode: PropTypes.string.isRequired
};

export default AuthenticationCentered;
