import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Query, withApollo} from 'react-apollo';
import _ from 'lodash';
import {
  ImageList,
  ImageListItem,
  ImageListImage,
  ImageListSupporting,
  ImageListLabel
} from '@rmwc/image-list';
import {Icon} from '@rmwc/icon';
import {Button} from '@rmwc/button';
import {Select} from '@rmwc/select';

import {Enums, enumToOptions} from '@tripp/shared';
import {listExperiences, deleteExperience} from './ExperienceQueries';

import './Experience.scss';

class ExperienceListItem_ extends Component {
  render () {
    const {id, name} = this.props;
    const src = _.get(this.props, 'image.sizes[0].url', 'https://i.imgur.com/SOIlHxO.jpg');

    return (
      <ImageListItem
        style={{
          margin: '2px',
          width: '180px'
        }}
      >
        <ImageListImage
          onClick={this.gotoExperience}
          id={id}
          src={src}
          alt="no preview"
        />
        <ImageListSupporting>
          <ImageListLabel>
            {name}
          </ImageListLabel>
          <Icon
            strategy="ligature"
            onClick={this.deleteExperience}
            icon="delete"
          />
        </ImageListSupporting>
      </ImageListItem>
    );
  }

  gotoExperience = async ()=> {
    const {router, id} = this.props;
    const url = `/experiences/${id}`;
    router.go(url);
  }

  deleteExperience = async (evt)=> {
    evt.preventDefault();
    const {client, id, status} = this.props;
    await client.mutate({
      mutation: deleteExperience,
      variables: {id},
      refetchQueries: [{query: listExperiences, variables: {status}}]
    });
  }
}

ExperienceListItem_.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  status: PropTypes.string.isRequired
};

const ExperienceListItem = withApollo(ExperienceListItem_);

class ExperienceListPage extends Component {
  constructor (props) {
    super(props);
    this.state = {status: Enums.ExperienceStatus.Published};
  }

  render () {
    const {status} = this.state;
    const {router} = this.props;
    const statusOptions = enumToOptions(Enums.ExperienceStatus);
    const variables = {status};

    return (
      <div className="Page ExperienceListPage">
        <Button onClick={this.gotoCreateExperience}>Create New</Button>
        <Select
          value={status}
          onChange={this.handleStatusSelect}
          label="Status"
          options={statusOptions}
        />
        <ImageList>
          <Query query={listExperiences} variables={variables} onCompleted={()=> { console.log('query successful'); }}>
            {({loading, error, data})=> {
              if (loading) {
                return <div>Loading...</div>;
              }

              if (error) {
                return <div>Error :(</div>;
              }

              const experiences = data.listExperiences;
              return experiences.map((experience)=> {
                const {id, name, image} = experience;
                return (
                  <ExperienceListItem
                    key={id}
                    id={id}
                    image={image}
                    name={name}
                    router={router}
                    status={status}
                  />
                );
              });
            }}
          </Query>
        </ImageList>
      </div>
    );
  }

  handleStatusSelect = (e)=> {
    this.setState({status: e.target.value});
  }

  gotoCreateExperience = (evt)=> {
    evt.preventDefault();
    const {router} = this.props;
    router.go('/experiences/create');
  };
}

ExperienceListPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default ExperienceListPage;
