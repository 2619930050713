import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {LinearProgress} from '@rmwc/linear-progress';
import {Typography} from '@rmwc/typography';

import './SessionLoading.scss';

class SessionLoadingPage extends Component {
  render () {
    const {message} = this.props;
    return (
      <div className="SessionLoadingPage">
        <div className="SessionLoadingWrapper">
          <Typography use="subtitle1" className="SessionLoadingMessage">
            {message}
          </Typography>
          <LinearProgress/>
        </div>
      </div>
    );
  }
}

SessionLoadingPage.propTypes = {
  message: PropTypes.string.isRequired
};

export default SessionLoadingPage;
