import React from 'react';
import PropTypes from 'prop-types';

import {Application} from '@tripp/shared';
import router from './router';

import './AdminApplication.scss';

class AdminApplication extends React.Component {
  render () {
    const {pathname} = this.props;

    return (
      <Application
        menu={this.menu}
        title="TRIPP Admin"
        router={router}
        pathname={pathname}
      />
    );
  }

  menu () {
    return [
      {
        label: 'Account',
        submenu: [
          {
            icon: 'search',
            label: 'Search',
            url: '/account/search'
          },
          {
            icon: 'add',
            label: 'Add Entitlement',
            url: '/account/entitlement/add'
          }
        ]
      },
      {
        label: 'Content',
        submenu: [
          {
            icon: 'add',
            label: 'Add Video Content',
            url: '/content/video/add'
          }
        ]
      },
      {
        label: 'Experiences',
        submenu: [
          {
            icon: 'list',
            label: 'List',
            url: '/experiences'
          },
          {
            icon: 'calendar_today',
            label: 'Schedule (legacy)',
            url: '/experiences/schedule'
          }
        ]
      },
      {
        label: 'Schedule',
        submenu: [
          {
            icon: 'list',
            label: 'Scheduled Tripps',
            url: '/schedule'
          },
          {
            icon: 'add',
            label: 'Uploader',
            url: '/schedule/uploader'
          }
        ]
      },
      {
        label: 'Companies',
        url: '/companies'
      },
      {
        label: 'Multi Use Licenses',
        url: '/multiuselicenses'
      },
      {
        label: 'Devices',
        submenu: [
          {
            icon: 'list',
            label: 'List',
            url: '/devices'
          },
          {
            icon: 'add',
            label: 'Create/Pair',
            url: '/devices/pairing'
          }
        ]
      },
      {
        label: 'Statistics',
        url: '/statistics'
      },
      {
        label: 'Experience Types',
        submenu: [
          {
            icon: 'list',
            label: 'Catalog',
            url: '/catalog'
          },
          {
            icon: 'add',
            label: 'Create',
            url: '/catalog/create'
          }
        ]
      }
    ];
  }
}

AdminApplication.propTypes = {
  pathname: PropTypes.string.isRequired
};

export default AdminApplication;
