import React, {Component} from 'react';
import {withApollo} from 'react-apollo';
import PropTypes from 'prop-types';
import {Button} from '@rmwc/button';
import {LinearProgress} from '@rmwc/linear-progress';

class AsyncButton extends Component {
  state = {
    loading: false
  };

  render () {
    const {loading} = this.state;
    const {children, disabled, gradient} = this.props;

    let $loading = '';
    if (loading) {
      $loading = (
        <div className="AsyncButtonLoader">
          <LinearProgress/>
        </div>
      );
    }

    let buttonClassName = '';
    if (gradient && !(disabled || loading)) {
      buttonClassName = 'GradientButton';
    }

    return (
      <div className="AsyncButton">
        <Button
          raised
          className={buttonClassName}
          disabled={disabled || loading}
          onClick={this.onClick}
        >
          {children}
        </Button>
        {$loading}
      </div>
    );
  }

  componentWillUnmount () {
    this.unmounted = true;
  }

  onClick = async (evt)=> {
    evt.preventDefault();
    const {action} = this.props;
    this.setState({loading: true});
    try {
      await action();
    } catch (error) {
      //todo: show error messaging
      console.error('AsyncButton error', error);
    }
    if (!this.unmounted) {
      this.setState({loading: false});
    }
  }
}

AsyncButton.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  client: PropTypes.object.isRequired,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default withApollo(AsyncButton);
