import gql from 'graphql-tag';

const listCompanies = gql`
  query {
    listCompanies {
      name
      id
      contact
      adminDomains
      createdAt
      updatedAt
    }
  }
`;

const getCompany = gql`
  query getCompany ($id: FirestoreID!) {
    getCompany(id: $id) {
      id
      name
      contact
      adminDomains
      createdAt
      updatedAt
      workers {
        id
        type
        adminType
        seatGroup
        seatLocation
        createdAt
        updatedAt
        account {
          id
          email
          personas {
            id
            username
            displayName
            avatar {
              sizes {
                height
                width
                url
              }
            }
          }
        }
      }
    }
  }
`;

const createCompany = gql`
  mutation createCompany($input: CompanyCreateInput!) {
    createCompany(input: $input) {
      id
      name
    }
  }
`;

const updateCompany = gql`
  mutation updateCompany($input: CompanyUpdateInput!, $id: FirestoreID!) {
    updateCompany(id: $id, input: $input) {
      id
    }
  }
`;

const deleteCompany = gql`
  mutation deleteCompany ($id: FirestoreID!) {
    deleteCompany(id: $id) {
      deletedAt
      deleted
    }
  }
`;

const addCompanyWorker = gql`
  mutation addCompanyWorker ($id: FirestoreID!, $input: CompanyWorkerAddInput!) {
    addCompanyWorker(id: $id, input: $input) {
      id
    }
  }
`;

const updateCompanyWorker = gql`
  mutation addCompanyWorker ($id: FirestoreID!, $input: CompanyWorkerUpdateInput!) {
    updateCompanyWorker(id: $id, input: $input) {
      id
    }
  }
`;

const deleteCompanyWorker = gql`
  mutation deleteCompanyWorker($id: CompoundID!) {
    deleteCompanyWorker(id: $id) {
      deletedAt
      deleted
    }
  }
`;

const getAccountByEmail = gql`
  query getAccountByEmail ($email: Email!) {
    getAccountByEmail(email: $email) {
      id
    }
  }
`;

const mixpanelExport = gql`
  query mixpanelExport ($fromDate: Moment!, $toDate: Moment!, $event: [String], $where: String) {
    mixpanelExport (fromDate: $fromDate, toDate: $toDate, event: $event, where: $where)
}`;


export {
  listCompanies,
  getCompany,
  createCompany,
  updateCompany,
  deleteCompany,
  addCompanyWorker,
  updateCompanyWorker,
  deleteCompanyWorker,
  getAccountByEmail,
  mixpanelExport
};
