import React, {Component} from 'react';
import {withApollo} from 'react-apollo';
import {TextField} from '@rmwc/textfield';
import {Button} from '@rmwc/button';
import {ListItem, List} from '@rmwc/list';
import Moment from 'moment';
import _ from 'lodash';
import {Drawer, DrawerHeader, DrawerTitle, DrawerSubtitle, DrawerContent} from '@rmwc/drawer';

import PropTypes from 'prop-types';
import {firebaseGetPasswordResetLink, getAccountByEmail, getAccountByPersonaUsername} from './AccountQueries';

const subscriptionPlanEnum = {
  year: 'Premium Annual',
  month: 'Premium Monthly',
  week: 'Premium Weekly',
  day: 'Premium Daily'
};

class AccountSearch extends Component {
  state = {
    activeTab: 0,
    setActiveTab: 0,
    loading: true,
    email: '',
    username: '',
    error: null,
    passwordResetLink: '',
    data: {
      id: '',
      email: '',
      type: '',
      country: '',
      region: '',
      billing: '',
      entitlements: [],
      devices: [],
      personas: [{
        runs: []
      }]
    }
  };


  render () {
    const $searchEmail = this.renderEmailSearch();
    const $searchUsername = this.renderUsernameSearch();
    const $data = this.state.data;
    const $error = this.state.error;
    const $passwordResetLink = this.state.passwordResetLink;
    const $account = this.renderAccount($data);
    const $billing = this.renderBilling($data);
    const $entitlements = this.renderEntitlements($data);
    const $devices = this.renderDevices($data);
    const $runs = this.renderRuns($data);
    return (
      <span>
        {$searchEmail}
        <span style={{float: 'left', padding: '10px'}}>
          <Button onClick={this.getAccountByEmail}>
            submit
          </Button>
          <Button onClick={this.clearForm}>
            clear email & data
          </Button>
          <Button onClick={this.generatePasswordResetLink}>
            Generate password reset link
          </Button>
        </span>
        {$searchUsername}
        <span style={{float: 'left', padding: '10px'}}>
          <Button onClick={this.getAccountByUsername}>
            submit
          </Button>
          <Button onClick={this.clearForm}>
            clear username & data
        </Button>
        </span>
        <span style={{float: 'left', padding: '15px', color: 'red'}}>{$error}</span>
        <span style={{float: 'left', padding: '15px'}}>{$passwordResetLink}</span>
        {$account}
        {$billing}
        {$entitlements}
        {$devices}
        {$runs}
      </span>
    );
  }

  renderEmailSearch () {
    return (
      <div>
        <div className="FormField">
          <TextField
            label="User's Email"
            onChange={this.handleFieldChange('email')}
            value={this.state.email}
          />
        </div>
      </div>
    );
  }

  renderUsernameSearch () {
    return (
      <div>
        <div className="FormField">
          <TextField
            label="Username (case sensitive)"
            onChange={this.handleFieldChange('username')}
            value={this.state.username}
          />
        </div>
      </div>
    );
  }

  renderAccount ($data) {
    return (
      <span style={{float: 'left', padding: '30px'}}>
        <Drawer>
           <DrawerHeader>
             <DrawerTitle style={{color: 'purple', fontWeight: 'normal', paddingBottom: '5px'}}>Account</DrawerTitle>
             <DrawerSubtitle>email: {$data.email}</DrawerSubtitle>
           </DrawerHeader>
           <DrawerContent>
              <List>
              <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> ACCOUNT ID: {$data.id}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> ACCOUNT TYPE: {$data.type}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> ROLE: {$data.role}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> MANTRA: {$data.personas.length > 0 ? $data.personas[0].mantra : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> USERNAME: {$data.personas.length > 0 ? $data.personas[0].username : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> FIRST NAME: {$data.personas.length > 0 ? $data.personas[0].firstname : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> LAST NAME: {$data.personas.length > 0 ? $data.personas[0].lastname : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> DISPLAY NAME: {$data.personas.length > 0 ? $data.personas[0].displayName : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> GENDER: {$data.personas.length > 0 ? $data.personas[0].gender : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> DOB: {Moment($data.dob).format('MM/DD/YYYY')}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> COUNTRY: {$data.country}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}> REGION: {$data.region}</ListItem>
              </List>
          </DrawerContent>
      </Drawer>
        </span>
    );
  }

  renderBilling ($data) {
    return (
      <span style={{float: 'left', padding: '30px'}}>
        <Drawer>
           <DrawerHeader>
             <DrawerTitle style={{color: 'purple', fontWeight: 'normal', paddingBottom: '5px'}}>Billing</DrawerTitle>
             <DrawerSubtitle>Purchased: {$data.billing.createdAt ? Moment($data.billing.createdAt).format('MM/DD/YYYY') : ''}</DrawerSubtitle>
           </DrawerHeader>
           <DrawerContent>
              <List>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>STATUS: {$data.billing.subscription ? $data.billing.subscription.status : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>SUB ID: {$data.billing.subscription ? $data.billing.subscription.stripeSubscriptionId : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>PLAN: {$data.billing.interval ? subscriptionPlanEnum[$data.billing.interval] : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>LAST UPDATED: {$data.billing.updatedAt ? Moment($data.billing.updatedAt).format('MM/DD/YYYY') : ''}</ListItem>
              </List>
          </DrawerContent>
      </Drawer>
        </span>
    );
  }

  renderEntitlements ($data) {
    return (
      <span style={{float: 'left', padding: '30px'}}>
        <Drawer>
           <DrawerHeader>
             <DrawerTitle style={{color: 'purple', fontWeight: 'normal', paddingBottom: '5px'}}>Entitlements</DrawerTitle>
             <DrawerSubtitle>Purchased: {$data.entitlements.length > 0 ? Moment($data.entitlements[$data.entitlements.length-1].createdAt).format('MM/DD/YYYY') : ''}</DrawerSubtitle>
             <DrawerSubtitle>Last Updated: {$data.entitlements.length > 0 ? Moment($data.entitlements[$data.entitlements.length-1].updatedAt).format('MM/DD/YYYY') : ''}</DrawerSubtitle>

           </DrawerHeader>
           <DrawerContent>
              <List>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>VENDOR: {$data.entitlements.length > 0 ? $data.entitlements[$data.entitlements.length-1].vendor : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>STATUS: {$data.entitlements.length > 0 ? $data.entitlements[$data.entitlements.length-1].status : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>SKU: {$data.entitlements.length > 0 ? $data.entitlements[$data.entitlements.length-1].sku : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>PLAN: {$data.entitlements.length > 0 
                && $data.entitlements[$data.entitlements.length-1].sku != 'MEMBERSHIP_LIFETIME' 
                && $data.entitlements[$data.entitlements.length-1].sku != 'PLAYSTATION_LIFETIME' 
                && $data.entitlements[$data.entitlements.length-1].subscription != null ? $data.entitlements[$data.entitlements.length-1].subscription.type: 'LIFETIME'}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>Subscription Start Date: {$data.entitlements.length > 0 
                && $data.entitlements[$data.entitlements.length-1].sku != 'MEMBERSHIP_LIFETIME' 
                && $data.entitlements[$data.entitlements.length-1].sku != 'PLAYSTATION_LIFETIME'
                && $data.entitlements[$data.entitlements.length-1].subscription != null ? $data.entitlements[$data.entitlements.length-1].subscription.currentPeriodStart : ''}</ListItem>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>Subscription End Date: {$data.entitlements.length > 0 
                && $data.entitlements[$data.entitlements.length-1].sku != 'MEMBERSHIP_LIFETIME' 
                && $data.entitlements[$data.entitlements.length-1].sku != 'PLAYSTATION_LIFETIME' 
                && $data.entitlements[$data.entitlements.length-1].subscription != null ? $data.entitlements[$data.entitlements.length-1].subscription.currentPeriodEnd : ''}</ListItem>
              </List>
          </DrawerContent>
      </Drawer>
        </span>
    );
  }

  renderDevices ($data) {
    return (
      <span style={{float: 'left', padding: '30px'}}>
        <Drawer>
          <DrawerHeader>
            <DrawerTitle style={{color: 'purple', fontWeight: 'normal', paddingBottom: '5px'}}>Device</DrawerTitle>
          </DrawerHeader>
          <DrawerContent>
            <List>
              <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>Model: {$data.devices.length > 0 ? $data.devices[$data.devices.length - 1].model : ''}</ListItem>
              <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>Updated: {$data.devices.length > 0 ? Moment($data.devices[$data.devices.length - 1].updatedAt).format('MM/DD/YYYY') : ''}</ListItem>
            </List>
          </DrawerContent>
        </Drawer>
      </span>
    );
  }

  renderRuns ($data) {
    let lastRun = {
      createdAt: ''
    };

    if ($data.personas.length > 0 && $data.personas[0].runs.length > 0) {
      lastRun = $data.personas[0].runs[$data.personas[0].runs.length - 1];
    }
    const $lastRunDate = lastRun.createdAt ? Moment(lastRun.createdAt).format('MM/DD/YYYY') : '';
    const $totalRuns = ($data.personas.length > 0 && $data.personas[0].runs.length > 0)
      ? $data.personas[0].runs.length - 1 : 0;
    return (
      <span style={{float: 'left', padding: '30px'}}>
        <Drawer>
           <DrawerHeader>
             <DrawerTitle style={{color: 'purple', fontWeight: 'normal', padding: '5px'}}>Last Run</DrawerTitle>
             <DrawerSubtitle style={{color: 'purple', fontWeight: 'lighter'}}>{$lastRunDate}</DrawerSubtitle>
           </DrawerHeader>
           <DrawerContent>
              <List>
                <ListItem style={{color: 'purple', fontWeight: 'lighter'}}>TOTAL RUNS: {$totalRuns}</ListItem>
              </List>
          </DrawerContent>
      </Drawer>
        </span>
    );
  }

  validateEmail = (email)=> {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  clearForm = ()=> {
    this.setState({
      activeTab: 0,
      setActiveTab: 0,
      loading: true,
      email: '',
      username: '',
      error: null,
      passwordResetLink: '',
      data: {
        id: '',
        email: '',
        type: '',
        country: '',
        region: '',
        billing: {
          createdAt: '',
          interval: '',
          subscription: {
            status: '',
            stripeSubscriptionId: ''
          }
        },
        entitlements: [],
        devices: [],
        personas: [{
          runs: []
        }]
      }
    }, this.props.newWorkerToggle);
  }

  generatePasswordResetLink = async ()=> {
    this.setState({error: null, passwordResetLink: ''});
    try {
      const {client} = this.props;
      const variables = {email: this.state.email};
      const query = firebaseGetPasswordResetLink;
      const result = await client.query({
        query,
        variables
      });
      if (result.data.firebaseGetPasswordResetLink) {
        this.setState({passwordResetLink: result.data.firebaseGetPasswordResetLink});
      } else {
        this.setState({passwordResetLink: `No account found for email "${this.state.email}"`});
      }
    } catch (error) {
      this.setState({error: `Couldn't generate password reset link: ${error}`});
      console.error('Error generating password reset link', error);
    }
  }


  handleFieldChange = (name)=> {
    return (event)=> {
      const {value} = event.target;
      this.setState({[name]: value});
    };
  };

  getAccountByUsername = async ()=> {
    const {client} = this.props;
    const {username} = this.state;
    this.setState({error: null});

    if (!username) {
      this.setState({error: 'no email has been entered'});
      return;
    }
    const variables = {username};
    const query = getAccountByPersonaUsername;
    try {
      const result = await client.query({
        query,
        variables
      });

      if (result.data.getAccountByPersonaUsername !== null) {
        const data = {...this.state.data};
        const {id, type, country, region, email,
          personas, entitlements, billing, role, dob, devices} = result.data.getAccountByPersonaUsername;
        data.id = id;
        data.country = country;
        data.region = region;
        data.email = email;
        data.type = type;
        data.role = role;
        data.dob = dob;
        if (personas) data.personas = personas;
        if (entitlements) data.entitlements = entitlements;
        if (devices) data.devices = _.sortBy(devices, [({updatedAt})=> new Date(updatedAt)]);
        if (billing) {
          data.billing = billing;
          const stripePlan = _.get(result.data.getAccountByPersonaUsername, 'billing.subscription.stripePlan');
          const {interval} = stripePlan;
          billing.interval = interval;
        } else {
          data.billing = '';
        }
        this.setState({data});
        return;
      }
      this.setState({error: 'No account found.'});
    } catch (error) {
      console.error('Error fetching account', error);
      throw Error;
    }
  }

  getAccountByEmail = async ()=> {
    const {client} = this.props;
    const {email} = this.state;
    this.setState({error: null});

    if (!email) {
      this.setState({error: 'no email has been entered'});
      return;
    } else if (!this.validateEmail(email)) {
      this.setState({error: 'badly formatted email'});
      return;
    }
    const variables = {email};
    const query = getAccountByEmail;
    try {
      const result = await client.query({
        query,
        variables
      });

      if (result.data.getAccountByEmail !== null) {
        const data = {...this.state.data};
        const {id, type, country, region, email,
          personas, entitlements, billing, role, dob, devices} = result.data.getAccountByEmail;
        data.id = id;
        data.country = country;
        data.region = region;
        data.email = email;
        data.type = type;
        data.role = role;
        data.dob = dob;
        if (personas) data.personas = personas;
        if (entitlements) data.entitlements = entitlements;
        if (devices) data.devices = _.sortBy(devices, [({updatedAt})=> new Date(updatedAt)]);
        if (billing) {
          data.billing = billing;
          const stripePlan = _.get(result.data.getAccountByEmail, 'billing.subscription.stripePlan');
          const {interval} = stripePlan;
          billing.interval = interval;
        } else {
          data.billing = '';
        }
        this.setState({data});
        return;
      }
      this.setState({error: 'No account found.'});
    } catch (error) {
      console.error('Error fetching account', error);
      throw Error;
    }
  }
}

AccountSearch.propTypes = {
  client: PropTypes.object.isRequired
};

export default withApollo(AccountSearch);
