import React from 'react';
import ReactDOM from 'react-dom';

import Application from './Application';
import * as serviceWorker from './serviceWorker';

const {location, document} = window;
const {pathname} = location;
const application = <Application pathname={pathname} />;
ReactDOM.render(application, document.getElementById('root'));

serviceWorker.unregister();
