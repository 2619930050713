import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {
  PusherMessages,
  session
} from '@tripp/shared';

import './Device.scss';

class DevicePairingMessagesPage extends Component {
  render () {
    const {deviceId} = this.props.params;
    const accountId = session.account.id;
    return (
      <div>
        <div>
          <div>Pusher messages for Device: {deviceId}</div>
        </div>
        <PusherMessages
          channel="devices"
          event={`${deviceId}/paired`}
        />
        <hr/>
        <div>
          <div>Pusher messages for Account: {accountId}</div>
        </div>
        <PusherMessages
          channel="accounts"
          event={`${accountId}/logout`}
        />
      </div>
    );
  }
}

DevicePairingMessagesPage.propTypes = {
  params: PropTypes.shape({
    id: PropTypes.string.isRequired,
    deviceId: PropTypes.string.isRequired
  })
};

export default DevicePairingMessagesPage;
