import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Typography} from '@rmwc/typography';
import {Button} from '@rmwc/button';
import {TextField} from '@rmwc/textfield';
import {Elevation} from '@rmwc/elevation';

import {AsyncButton, ImageUploader} from '@tripp/shared';
import {
  deleteCatalogEntryImage,
  getCatalogEntry,
  updateCatalogEntry
} from './CatalogQueries';

import './Catalog.scss';

class CatalogEntryEditPage extends Component {
  constructor (props) {
    super(props);
    this.uploader = null;
    this.state = {
      loading: true,
      catalogEntry: {type: ''}
    };
  }

  async componentDidMount () {
    if (this.state.loading) {
      try {
        const catalogEntry = await this.getCatalogEntry();
        console.log(catalogEntry);

        this.setState({
          catalogEntry,
          loading: false
        });
      } catch (e) {
        console.log('Something went wrong', e);
        throw e;
      }
    }
  }

  render () {
    const {router, params} = this.props;
    const {loading} = this.state;
    if (loading) {
      return <h1>Loading</h1>;
    }
    const {
      image,
      localization: {
        name,
        description,
        footerText
      }
    } = this.state.catalogEntry;

    return (
      <div className="Page CatalogEntryEditPage Centered">
        <Elevation z={3} className="Centered">
          <Button onClick={()=> { router.go(`/catalog/${params.id}`); }}>
            back
          </Button>

          {this.renderImage(image)}

          <div>
            <TextField
              label='Experience Name'
              value={name}
              onChange={this.setName}
            />
          </div>
          <br />
          <div>
            <TextField
              textarea
              outlined
              label='Description'
              value={description}
              onChange={this.setDescription}
            />
          </div>
          <br />
          <div>
            <TextField
              textarea
              outlined
              label='Footer'
              value={footerText}
              onChange={this.setFooter}
            />
          </div>

          <div>
            <Button onClick={this.updateCatalogEntry}>
              submit
            </Button>
          </div>
        </Elevation>
      </div>
    );
  }

  renderImage (image) {
    let $body;
    if (image) {
      const thumbnail = image.sizes.find(({type})=> {
        return type === 'SquareSmall';
      });
      const url = thumbnail ? thumbnail.url : image.sizes[0].url;

      $body = (
        <div className="EntryImage">
          <img
            src={url}
            alt="Catalog Entry"
          />
          <AsyncButton action={this.deleteImage}>
            Delete image
          </AsyncButton>
        </div>

      );
    } else {
      $body = (
        <ImageUploader
          multi={false}
          showUploadButton={false}
          ref={(uploader)=> {
            if (uploader) {
              this.uploader = uploader.wrappedInstance;
            }
          }}
        />
      );
    }

    return (
      <div className="ImageAvatar">
        <Typography
          className="FormTitle"
          use="subtitle1"
        >
          Image
        </Typography>
        {$body}
      </div>
    );
  }


  newWorkerToggle = ()=> {
    const {newWorker} = this.state;
    this.setState({newWorker: !newWorker});
  }

  // Getters/Setters
  setName = (e)=> {
    const catalogEntry = {...this.state.catalogEntry};
    catalogEntry.type = e.target.value;
    catalogEntry.localization.name = e.target.value;
    this.setState({catalogEntry});
  }

  setDescription = (e)=> {
    const catalogEntry = {...this.state.catalogEntry};
    catalogEntry.localization.description = e.target.value;
    this.setState({catalogEntry});
  }

  setFooter = (e)=> {
    const catalogEntry = {...this.state.catalogEntry};
    catalogEntry.localization.footerText = e.target.value;
    this.setState({catalogEntry});
  }

  deleteImage = ()=> {
    const {catalogEntry} = this.state;
    catalogEntry.image = null;
    this.setState({catalogEntry});
  }

  // GraphQL Queries/Mutations
  getCatalogEntry = async ()=> {
    const {client} = this.props;
    const {id} = this.props.params;
    const variables = {id, locale: 'en'};

    const result = await client.query({
      query: getCatalogEntry,
      variables
    });

    return result.data.getCatalogEntry;
  }

  updateCatalogEntry = async ()=> {
    const {router, client} = this.props;
    const {id} = this.props.params;
    const {localization: {name, description, footerText, locale}} = this.state.catalogEntry;
    const input = {
      localizations: [{name, description, footerText, locale}]
    };
    const variables = {id, input};

    const mutation = updateCatalogEntry;
    try {
      if (this.uploader && this.uploader.hasFiles()) {
        input.image = await this.uploader.upload();
        console.log('Uploader has an image');
        console.log(input);
      }
      console.log('variables: ', variables);
      await client.mutate({
        mutation,
        variables,
        refetchQueries: [{query: getCatalogEntry, variables: {id, locale: 'en'}}]
      });
      router.go(`/catalog/${id}`);
    } catch (error) {
      console.error('Error updating catalog entry', error);
      throw Error;
    }
  }
}

CatalogEntryEditPage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default withApollo(CatalogEntryEditPage);
