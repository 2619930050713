import ApolloClient from 'apollo-boost';

import {session} from './Session';

const client = new ApolloClient({
  uri: process.env.REACT_APP_GRAPHQL_URI,
  request: (operation)=> {
    if (session.signedIn()) {
      const {token} = session;
      const headers = {
        Authorization: `Bearer ${token}`
      };
      operation.setContext({headers});
    }
  }
});

session.setClient(client);

export default client;
