import React, { Component } from 'react'
import {withApollo} from 'react-apollo';
import {stripeCharge, addEntitlementByEmail, getAccountByEmail} from './AccountQueries';
import {TextField} from '@rmwc/textfield';
import {Button} from '@rmwc/button';
import {ListItem, List} from '@rmwc/list';
import Moment from 'moment';
import _ from 'lodash';
import {Drawer, DrawerHeader, DrawerTitle, DrawerSubtitle, DrawerContent} from '@rmwc/drawer';

import PropTypes from 'prop-types';

class AccountEntitlement extends Component {

  state = {
    activeTab: 0,
    setActiveTab: 0,
    loading: true,
    entEmail: '',
    stripeEmail: '',
    sku: '',
    vendor: '',
    vendorToken: '',
    purchaseId: '',
    type: '',
    amount: '',
    description: '',
    error1: null,
    error2: null

  };

  render () {
    const entitlementForm = this.renderEntitlementForm();
    const stripeChargeForm = this.renderStripeCharge();
    const error1 = this.state.error1;
    const error2= this.state.error2;
    return (
      <span>
        <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', paddingLeft: '20px', margin:'center', color: 'red', fontWeight:'lighter'}}>{error1}</div>
        {entitlementForm}
        <div style={{float:'center', paddingLeft:'20px', paddingTop:'10px', paddingBottom: '20px'}}>
          <Button style={{fontWeight:'heavy', fontSize:'15px'}} onClick={this.addEntitlement}>
            Add Entitlement
          </Button>
          <Button onClick={this.clearForm}>
            RESET FORM
        </Button>
        </div>
        <div style={{ fontFamily: 'Arial, Helvetica, sans-serif', paddingLeft: '20px', margin:'center', color: 'red', fontWeight:'lighter'}}>{error2}</div>
        {stripeChargeForm}
        <div style={{float:'center', paddingLeft:'20px', paddingTop:'10px'}}>
          <Button style={{fontWeight:'heavy', fontSize:'15px'}} onClick={this.stripeCharge}>
            Submit Charge
          </Button>
          <Button onClick={this.clearForm}>
            RESET FORM
        </Button>
        </div>
      </span>
    )
  }

  renderEntitlementForm () {
    return (
      <div>
      <div style={{width: '400px', borderStyle: 'solid', color: 'purple', fontWeight: 'lighter', fontFamily: 'Arial, Helvetica, sans-serif', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '20px', textAlign: 'left', fontSize: '15px'}}>
        Add an entitlement to a user's account by email </div>
      <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
        <TextField
          label="User's Email"
          onChange={this.handleFieldChange('entEmail')}
          value={this.state.entEmail}
        />
      </div>
      <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
        <TextField
          label="SKU"
          onChange={this.handleFieldChange('sku')}
          value={this.state.sku}
        />
      </div>
      <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
        <TextField
          label="Vendor (eg. Stripe or Oculus)"
          onChange={this.handleFieldChange('vendor')}
          value={this.state.vendor}
        />
      </div>
      <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
        <TextField
          label="Type (eg. Consumable or Nonconsumable)"
          onChange={this.handleFieldChange('type')}
          value={this.state.type}
        />
      </div>
    </div>
  )}

  renderStripeCharge () {
    return (
      <div>
        <div style={{width: '400px', borderStyle: 'solid', color: 'purple', fontWeight: 'lighter', fontFamily: 'Arial, Helvetica, sans-serif', paddingTop: '10px', paddingBottom: '10px', paddingLeft: '20px', textAlign: 'left', fontSize: '15px'}}>
          Make a charge through Stripe to a user's account by email </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="User's Email"
            onChange={this.handleFieldChange('stripeEmail')}
            value={this.state.stripeEmail}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Amount (without decimals, eg 1999 is 19.99)"
            onChange={this.handleFieldChange('amount')}
            value={this.state.amount}
          />
        </div>
        <div style={{width:'400px', paddingLeft:'20px'}} className="FormField">
          <TextField
            label="Description (eg. Lifetime membership for Hyosung)"
            onChange={this.handleFieldChange('description')}
            value={this.state.description}
          />
        </div>
      </div>
    )}

  handleFieldChange = (name)=> {
    return (event)=> {
      const {value} = event.target;
      this.setState({[name]: value});
    };
  };

  addEntitlement = async ()=> {
    const {client} = this.props;
    const {entEmail, sku, vendor, type, vendorToken, purchaseId} = this.state;
    this.setState({error1: null, error2: null});

    if (!entEmail) {
      this.setState({error1: 'no email has been entered'});
      return;
    }
    if (!this.validateEmail(entEmail)) {
      this.setState({error1: 'badly formatted email'});
      return;
    }
    if (!type) {
      this.setState({error1: 'no type has been entered'});
      return;
    }
    if (!sku) {
      this.setState({error1: 'no SKU has been entered'});
      return;
    }
    if (!vendor) {
      this.setState({error1: 'no vendor has been entered'});
      return;
    }

    const accountVariables = {email: entEmail};
    const accountQuery = getAccountByEmail;
    try {
      const result = await client.query({
        query: accountQuery,
        variables: accountVariables
      });
      if (!result.data.getAccountByEmail) {
        this.setState({error1: 'No account found.'});
        return;
      }

      const entitlementVariables = {
        input: {
          email: entEmail,
          sku,
          type,
          vendor,
          purchaseId,
          vendorToken
        }
      }

      const entitlementMutation = addEntitlementByEmail;

      const entitlement = await client.mutate({
        mutation: entitlementMutation,
        variables: entitlementVariables
      })

      if (entitlement) {
        window.alert('entitlement has been added to the account');
      }
    } catch (error) {
      console.log(error);
    }
  }

  stripeCharge =  async ()=> {
    const {client} = this.props;
    const {stripeEmail, amount, description} = this.state;
    this.setState({error1: null, error2: null});

    if (!stripeEmail) {
      this.setState({error2: 'no email has been entered'});
      return;
    }
    if (!this.validateEmail(stripeEmail)) {
      this.setState({error2: 'badly formatted email'});
      return;
    }
    if (!amount) {
      this.setState({error2: 'no amount has been entered'});
      return;
    }

    const accountVariables = {email: stripeEmail};
    const accountQuery = getAccountByEmail;
    try {
      const result = await client.query({
        query: accountQuery,
        variables: accountVariables
      });
      if (!result.data.getAccountByEmail) {
        this.setState({error: 'No account found.'});
        return
      }

      const stripeChargeVariables = {
        input: {
          email: stripeEmail,
          amount: parseInt(amount),
          description
        }
      }

      const stripeChargeMutation = stripeCharge;

      const response = await client.mutate({
        mutation: stripeChargeMutation,
        variables: stripeChargeVariables
      });

      console.log(`the response: ${response.data.stripeCharge.success}`);
      if (response.data.stripeCharge.success === true) {
        window.alert('Stripe charge successfully submitted, check the Stripe dashboard');
      } else {
        window.alert('Stripe charge was not successfully submitted');
      }

      return
    } catch (error) {
      console.log(error);
    }
  }

  validateEmail = (email)=> {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  clearForm = ()=> {
    this.setState( {
      activeTab: 0,
      setActiveTab: 0,
      loading: true,
      entEmail: '',
      stripeEmail: '',
      sku: '',
      vendor: '',
      vendorToken: '',
      purchaseId: '',
      type: '',
      amount: '',
      description: '',
      error1: null,
      error2: null
    }, this.props.newWorkerToggle);
  }

}

AccountEntitlement.propTypes = {
  client: PropTypes.object.isRequired,
};

export default withApollo(AccountEntitlement);