import React from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';
import {Button} from '@rmwc/button';
import {Select} from '@rmwc/select';
import _ from 'lodash';
import Moment from 'moment';

import {
  AsyncButton,
  DateChooser,
  Enums,
  enumToOptions,
  getDay
} from '@tripp/shared';

import {
  getSchedule,
  updateExperience,
  updateScheduledExperience
} from './ExperienceQueries';

import './Experience.scss';

class ScheduleRow extends React.Component {
  constructor (props) {
    super(props);
    const {status} = props.experience;
    this.state = {
      editing: false,
      status
    };
  }

  render () {
    const {mode} = this.props;
    const method = `render${mode}`;
    return this[method]();
  }

  renderDay () {
    const {date, backfilled, experience, teaching, musicStem} = this.props;
    const {id, name, status, image, platform, category} = experience;
    const src = _.get(image, 'sizes[0].url', '');

    const classNames = ['ScheduleItem'];
    if (backfilled) {
      classNames.push('Backfilled');
    }

    const now = Moment();
    const isToday = now.isSame(date, 'day');
    if (isToday) {
      classNames.push('Today');
    }

    return (
      <tr
        className={classNames.join(' ')}
      >
        <td className="ScheduleDate">
          {getDay(date)} {date}
        </td>
        <td className="ScheduleExperienceImage" >
          <img
            src={src}
            alt={`screenshot of ${name}`}
            onClick={this.gotoExperience(id)}
          />
        </td>
        <td className="ScheduleExperienceName">
          {name}
        </td>
        <td className="ScheduleExperienceStatus">
          {status}
        </td>
        <td className="ScheduleTeaching">
          {teaching.name}
        </td>
        <td className="ScheduleMusicStem">
          {musicStem.name}
        </td>
        <td className="ScheduleExperienceEdit">
          {backfilled ? '' : this.renderEdit()}
        </td>
      </tr>
    );
  }

  renderUnavailable () {
    const {id, name, availableOn, status, image} = this.props.experience;
    const src = _.get(image, 'sizes[0].url', '');

    const UnavailabileClass = 'UnavailabilityCause';

    let availableOnClassNames = 'ScheduleUnvailableExperienceAvailableOn';
    if (!availableOn) {
      availableOnClassNames += ` ${UnavailabileClass}`;
    }

    let statusClassNames = 'ScheduleUnvailableExperienceStatus';
    if (status !== Enums.ExperienceStatus.Published) {
      statusClassNames += ` ${UnavailabileClass}`;
    }

    return (
      <tr
        className="ScheduleUnavailableExperience"
      >
        <td className="ScheduleUnvailableExperienceImage" >
          <img
            src={src}
            alt={`screenshot of ${name}`}
            onClick={this.gotoExperience(id)}
          />
        </td>
        <td className="ScheduleUnvailableExperienceName">
          {name}
        </td>
        <td className={availableOnClassNames}>
          {availableOn}
        </td>
        <td className={statusClassNames}>
          {status}
        </td>
        <td className="ScheduleExperienceEdit">
          {this.renderEdit()}
        </td>
      </tr>
    );
  }

  gotoExperience = (id)=> {
    return (evt)=> {
      evt.preventDefault();
      const {router} = this.props;
      const url = `/experiences/${id}`;
      router.go(url);
    };
  }

  renderEdit () {
    // const {availableOn} = this.props.experience;
    const {date} = this.props;
    const {editing, status} = this.state;

    if (!editing) {
      return (
        <Button onClick={this.toggleEdit}>
          Edit
        </Button>
      );
    }

    const statusOptions = enumToOptions(Enums.ExperienceStatus);

    return (
      <>
        <DateChooser
          date={date}
          ref={(dateChooser)=> {
            this.dateChooser = dateChooser;
          }}
          onChange={()=> {
            this.forceUpdate();
          }}
        />
        <div>
          <Select
            value={status}
            onChange={this.setStatus}
            label="Status"
            options={statusOptions}
          />
        </div>
        <AsyncButton
          action={this.update}
          disabled={!this.changed()}
        >
          Update
        </AsyncButton>
        <Button
          onClick={this.toggleEdit}
        >
          Cancel
        </Button>
      </>
    );
  }

  setStatus = (evt)=> {
    const status = evt.target.value;
    this.setState({status, statusChanged: true});
  }

  toggleEdit = (event)=> {
    event.preventDefault();
    const {editing} = this.state;
    this.setState({editing: !editing});
  };

  changed = ()=> {
    const dateChanged = (this.dateChooser && this.dateChooser.changed());
    const {statusChanged} = this.state;
    return (dateChanged || statusChanged);
  }

  update = async ()=> {
    const {client, experience, date} = this.props;
    const {id, type} = experience;
    const input = {};

    const dateChanged = this.dateChooser.changed();
    if (dateChanged) {
      input.availableOn = this.dateChooser.yyyymmdd();
    }
    const {status, statusChanged} = this.state;
    if (statusChanged) {
      input.status = status;
    }
    await client.mutate({
      mutation: updateExperience,
      variables: {input, id},
      refetchQueries: [
        {
          query: getSchedule,
          variables: {
            type,
            getPrevious: true,
            days: 30
          }
        }
      ]
    });
    const currentDate = Moment(date).format('YYYY/MM/DD');
    const newDate = Moment(input.availableOn).format('YYYY/MM/DD');
    await client.mutate({
      mutation: updateScheduledExperience,
      variables: {
        type: type,
        currentDate,
        newDate,
      },
      refetchQueries: [
        {
          query: getSchedule,
          variables: {
            type,
            getPrevious: false,
            days: 30
          }
        }
      ]
    });
    this.setState({editing: false});
  };
}

ScheduleRow.propTypes = {
  client: PropTypes.object.isRequired,
  router: PropTypes.object.isRequired,
  mode: PropTypes.string.isRequired,
  date: PropTypes.instanceOf(Date),
  backfilled: PropTypes.bool,
  experience: PropTypes.shape({
    id: PropTypes.string.isRequired,
    availableOn: PropTypes.string,
    status: PropTypes.string.isRequired,
    type: PropTypes.string,
    platform: PropTypes.string,
    category: PropTypes.string,
    name: PropTypes.string.isRequired,
    image: PropTypes.object
  }).isRequired,
  teaching: PropTypes.shape({
    name: PropTypes.string.isRequired
  }),
  musicStem: PropTypes.shape({
    name: PropTypes.string.isRequired
  })
};

export default withApollo(ScheduleRow);
