import React from 'react';
import CountryRegionData from 'country-region-data';
import {Select} from '@rmwc/select';

const countries = CountryRegionData.map((country)=> {
  const {countryName, countryShortCode} = country;
  return {
    label: countryName,
    value: countryShortCode
  };
});

class CountryRegionChooser extends React.Component {
  render () {
    const {onChangeCountry, onChangeRegion, country, region} = this.props;

    const regions = country ? this.regionsForCountry(country) : [];

    return (
      <>
        <div className="FormField">
          <Select
            label="Country"
            value={country}
            options={countries}
            onChange={onChangeCountry}
            placeholder=""
          />
        </div>

        <div className="FormField">
          <Select
            label="Region"
            value={region}
            options={regions}
            onChange={onChangeRegion}
            placeholder=""
            disabled={!country}
          />
        </div>
      </>
    );
  }

  regionsForCountry (countryCode) {
    const country = CountryRegionData.find((country)=> {
      return (country.countryShortCode === countryCode);
    });
    return country.regions.map((region)=> {
      const {name, shortCode} = region;
      return {
        label: name,
        value: shortCode
      };
    });
  }
}

export default CountryRegionChooser
