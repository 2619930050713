import gql from 'graphql-tag';

const getAccountByEmail = gql`
    query getAccountByEmail ($email: Email!) {
        getAccountByEmail(email: $email) {
            entitlements {
                id
                sku
                status
                createdAt
                vendor
                subscription {
                    currentPeriodStart
                    currentPeriodEnd
                    type
                }
            }
            subscriptionStatus
            billing {
                createdAt
                updatedAt
                subscription {
                    stripePlan
                    stripePlanId
                    stripeSubscriptionId
                    status
                }
            }
            devices {
                updatedAt
                model
            }
            dob
            role
            country
            region
            email
            isAdmin
            id
            type
            personas {
                displayName
                firstname
                lastname
                gender
                mantra
                username
                id
                weeklyRunsGoal {
                    type
                    status
                    value
                    progress
                }
                gifts {
                    accessType
                    media
                    {
                        sourceType
                        status
                        updatedAt
                        ... on Image { sizes { width height downloadUrl } }
                    }
                }
                runs {
                    id
                    createdAt
                    clientRunId
                    review {
                        id
                        runId
                        rating
                        description
                        persona {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const getAccountByPersonaUsername = gql`
    query getAccountByPersonaUsername ($username: String!) {
        getAccountByPersonaUsername(username: $username) {
            entitlements {
                id
                sku
                status
                createdAt
                updatedAt
                vendor
                subscription {
                    currentPeriodStart
                    currentPeriodEnd
                    type
                }
            }
            subscriptionStatus
            billing {
                createdAt
                updatedAt
                subscription {
                    stripePlan
                    stripePlanId
                    stripeSubscriptionId
                    status
                }
            }
            devices {
                updatedAt
                model
            }
            dob
            role
            country
            region
            email
            isAdmin
            id
            type
            personas {
                displayName
                firstname
                lastname
                gender
                mantra
                username
                id
                weeklyRunsGoal {
                    type
                    status
                    value
                    progress
                }
                gifts {
                    accessType
                    media
                    {
                        sourceType
                        status
                        updatedAt
                        ... on Image { sizes { width height downloadUrl } }
                    }
                }
                runs {
                    id
                    createdAt
                    clientRunId
                    review {
                        id
                        runId
                        rating
                        description
                        persona {
                            id
                        }
                    }
                }
            }
        }
    }
`;

const addEntitlementByEmail = gql`
    mutation addEntitlementForEmail ($input: EntitlementInput!) {
        addEntitlementForEmail (input: $input)
    }
`;

const stripeCharge = gql`
    mutation stripeCharge ($input: StripeChargeInput!) {
        stripeCharge(input: $input)
    }
`;

const firebaseGetPasswordResetLink = gql`
  query firebaseGetPasswordResetLink ($email: Email!) {
     firebaseGetPasswordResetLink (email: $email)
  }
`;

export {
  getAccountByEmail,
  getAccountByPersonaUsername,
  addEntitlementByEmail,
  stripeCharge,
  firebaseGetPasswordResetLink
};
