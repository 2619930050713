// from https://github.com/jamesmfriedman/rmwc/blob/master/src/docs/Submenu.js

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {ListItem, ListItemMeta, ListItemText} from '@rmwc/list';
import {Icon} from '@rmwc/icon';

import './Submenu.scss';

class Submenu extends React.Component {
  static propTypes = {
    label: PropTypes.node
  };

  state = {
    isOpen: false
  };

  render () {
    const {children, label} = this.props;
    return (
      <div className="submenu">
        <ListItem onClick={this.toggle}>
          <ListItemText>{label}</ListItemText>
          <ListItemMeta>
            <Icon
              className={classNames('submenu__icon', {
                'submenu__icon--open': this.state.isOpen
              })}
              icon="chevron_right"
            />
          </ListItemMeta>
        </ListItem>
        <div
          className={classNames('submenu__children', {
            'submenu__children--open': this.state.isOpen
          })}
        >
          {children}
        </div>
      </div>
    );
  }

  toggle = (evt)=> {
    evt.preventDefault();
    const {isOpen} = this.state;
    this.setState({isOpen: !isOpen});
  };
}

Submenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element
  ]).isRequired,
  label: PropTypes.string.isRequired
};

export default Submenu;
