import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Button} from '@rmwc/button';
import {TextField} from '@rmwc/textfield';
import {Elevation} from '@rmwc/elevation';

import {ImageUploader} from '@tripp/shared';
import {createCatalogEntry, listCatalogEntries} from './CatalogQueries';

import './Catalog.scss';

// TODO: Add image uploader component with two part create/update mutation
// process. See persona update for an example.
class CatalogEntryCreatePage extends Component {
  constructor () {
    super();
    this.uploader = null;

    this.state = {
      catalogEntry: {
        type: '',
        localizations: [{locale: 'en', name: '', description: '', footerText: ''}]
      }
    };
  }


  render () {
    const {router} = this.props;
    const [{
      name,
      description,
      footerText
    }] = this.state.catalogEntry.localizations;
    return (
      <div className="Page CatalogEntryPage Centered">
        <Elevation z={3} className="Centered">
          <Button onClick={()=> { router.go('/catalog'); }}>
            back
          </Button>
          <div>
            <TextField
              label='Experience Name'
              value={name}
              onChange={this.setName}
            />
          </div>
          <br />

          <ImageUploader
            multi={false}
            showUploadButton={false}
            ref={(uploader)=> {
              if (uploader) {
                this.uploader = uploader.wrappedInstance;
              }
            }}
          />

          <br />
          <div>
            <TextField
              textarea
              outlined
              label='Description'
              value={description}
              onChange={this.setDescription}
            />
          </div>
          <br />
          <div>
            <TextField
              textarea
              outlined
              label='Footer'
              value={footerText}
              onChange={this.setFooter}
            />
          </div>

          <div>
            <Button onClick={this.createCatalogEntry}>
              submit
            </Button>
          </div>
        </Elevation>
      </div>
    );
  }

  setName = (e)=> {
    const catalogEntry = {...this.state.catalogEntry};
    const name = e.target.value;
    catalogEntry.type = name;
    catalogEntry.localizations[0].name = name;
    this.setState({catalogEntry});
  }

  setDescription = (e)=> {
    const catalogEntry = {...this.state.catalogEntry};
    const description = e.target.value;
    catalogEntry.localizations[0].description = description;
    this.setState({catalogEntry});
  }

  setFooter = (e)=> {
    const catalogEntry = {...this.state.catalogEntry};
    const footerText = e.target.value;
    catalogEntry.localizations[0].footerText = footerText;
    this.setState({catalogEntry});
  }

  createCatalogEntry = async ()=> {
    const {client, router} = this.props;
    const input = this.state.catalogEntry;
    const variables = {input, locale: 'en'};
    const mutation = createCatalogEntry;
    try {
      if (this.uploader.hasFiles()) {
        input.image = await this.uploader.upload();
      }

      const result = await client.mutate({
        mutation,
        variables,
        refetchQueries: [{query: listCatalogEntries, variables: {locale: 'en'}}]
      });

      const {id} = result.data.createCatalogEntry;
      return router.go(`/catalog/${id}`);
    } catch (error) {
      console.error('Error updating catalog entry', error);
      throw error;
    }
  }
}

CatalogEntryCreatePage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

export default withApollo(CatalogEntryCreatePage);
