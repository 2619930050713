import React, {Component} from 'react';
import PropTypes from 'prop-types';

import {AuthenticationCentered} from '@tripp/shared';

class SignInPage extends Component {
  render () {
    const {router} = this.props;

    return (
      <div className="Page SigninPage VerticallyCenteredPage">
        <AuthenticationCentered
          router={router}
          mode="SignIn"
          allowSignup={false}
        />
      </div>
    );
  }

  static hideTopBar () {
    return true;
  }
}

SignInPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default SignInPage;
