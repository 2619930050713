import gql from 'graphql-tag';

const pairingFragment = `
  id
  status
  accountId
  account {
    id
    email
  }
  code
  expiresAt
`;

const deviceFragment = `
  id
  localId
  model
  type
  pairings {
    ${pairingFragment}
  }
`;

const listDevices = gql`
  query listDevices {
    listDevices {
      ${deviceFragment}
    }
  }
`;

const getDevice = gql`
  query getDevice ($id: FirestoreID!) {
    getDevice (id: $id) {
      ${deviceFragment}
    }
  }
`;

const getDeviceByLocalId = gql`
  query getDeviceByLocalId ($localId: String!) {
    getDeviceByLocalId (localId: $localId) {
      ${deviceFragment}
    }
  }
`;

const findOrCreateDeviceAndPairing = gql`
  mutation findOrCreateDeviceAndPairing (
    $input: FindOrCreateDeviceAndPairingInput!
  ) {
    findOrCreateDeviceAndPairing(input: $input) {
      id
    }
  }
`;

const pairDevice = gql`
  mutation pairDevice ($accountId: FirestoreID!, $code: PairingCode!) {
    pairDevice(accountId: $accountId, code: $code) {
      ${pairingFragment}
    }
  }
`;

const unpairDevice = gql`
  mutation unpairDevice ($pairingId: FirestoreID!) {
    unpairDevice(pairingId: $pairingId) {
      id
    }
  }
`;

export {
  listDevices,
  getDevice,
  getDeviceByLocalId,
  findOrCreateDeviceAndPairing,
  pairDevice,
  unpairDevice
};
