import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {TextField} from '@rmwc/textfield';
import {Select} from '@rmwc/select';
import Moment from 'moment';

import './DateChooser.scss';

const Months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];

class DateChooser extends Component {
  constructor (props) {
    super(props);
    let {date} = props;
    let year = '';
    let month = '';
    let day = '';
    if (date) {
      if (!Moment.isMoment(date)) {
        date = Moment(date);
      }
      year = date.year();
      month = date.month();
      day = date.date();
    }
    const changed = false;
    this.state = {year, month, day, changed};
  }

  render () {
    const {year, month, day} = this.state;

    const monthOptions = Months.map((label, value)=> {
      return {label, value};
    });

    return (
      <div className="DateChooser">
        <Select
          className="DateChooserMonth"
          label="Month"
          value={month}
          options={monthOptions}
          onChange={this.onChange('month')}
          placeholder=""
        />
        <TextField
          className="DateChooserDay"
          label="Day (DD)"
          pattern="\d{1,2}"
          value={day}
          onChange={this.onChange('day')}
        />
        <TextField
          className="DateChooserYear"
          label="Year (YYYY)"
          pattern="\d{4}"
          value={year}
          onChange={this.onChange('year')}
        />
      </div>
    );
  }

  changed = ()=> {
    return this.state.changed;
  }

  onChange = (name)=> {
    return (event)=> {
      if (event.preventDefault) {
        event.preventDefault();
      }
      this.setState({
        changed: true,
        [name]: event.target.value
      }, ()=> {
        const {onChange} = this.props;
        if (onChange) {
          const {day, month, year} = this.state;
          onChange({day, month, year});
        }
      });
    };
  }

  moment = ()=> {
    const {day, month, year} = this.state;
    return Moment({day, month, year});
  }

  yyyymmdd = ()=> {
    const moment = this.moment();
    return moment.format('YYYY/MM/DD');
  }
}

DateChooser.propTypes = {
  date: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string
  ]),
  onChange: PropTypes.func
};

export default DateChooser;
