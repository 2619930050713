import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Typography} from '@rmwc/typography';
import {List, ListItem} from '@rmwc/list';
import {Button} from '@rmwc/button';

import {listMultiUseLicenses} from './MultiUseQueries';

import './MultiUse.scss';

class MultiUseListPage extends Component {
  state = {
    multiUseLicenses: [],
    loading: true
  };

  async componentDidMount () {
    if (this.state.loading) {
      const multiUseLicenses = await this.listMultiUseLicenses();
      this.setState({
        loading: false,
        multiUseLicenses
      });
    }
  }

  render () {
    const {router} = this.props;
    const {loading, multiUseLicenses} = this.state;
    if (loading) {
      return <div>Loading</div>;
    }
    return (
      <div className="Page CompanyListPage">
        {/*<Button onClick={()=> { router.go('/companies/create'); }}>*/}
        {/*  New MultiUseLicense*/}
        {/*</Button>*/}
        <br />
        <Typography use="headline6">Companies</Typography>
        <List>
          {
            multiUseLicenses.map((el, id)=> (
              <ListItem key={id} onClick={()=> { router.go(`/multiUseLicenses/${el.id}`); }}>
                {el.name}
              </ListItem>
            ))
          }
        </List>
      </div>
    );
  }

  listMultiUseLicenses = async ()=> {
    const {client} = this.props;

    const result = await client.query({
      query: listMultiUseLicenses
    });

    return result.data.listMultiUseLicenses;
  }
}

MultiUseListPage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

export default withApollo(MultiUseListPage);
