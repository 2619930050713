import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Typography} from '@rmwc/typography';
import {Button} from '@rmwc/button';
import {Elevation} from '@rmwc/elevation';
import {List, ListItem} from '@rmwc/list';

import {getCompany} from './CompanyQueries';

import './Company.scss';

class CompanyPage extends Component {
  state = {
    loading: true
  };

  async componentDidMount () {
    const {loading} = this.state;
    if (loading) {
      const {id} = this.props.params;
      const company = await this.getCompany(id);
      this.setState({
        company,
        loading: false
      });
    }
  }

  render () {
    const {router, params} = this.props;
    const {loading} = this.state;
    if (loading) {
      return <h1>Loading</h1>;
    }
    const {
      name,
      contact,
      adminDomains,
      workers
    } = this.state.company;
    return (
      <div className="Page CompanyPage Centered">
        <Elevation z={3} className="Centered">
          <Button onClick={()=> { router.go('/companies'); }}>
            back
          </Button>
          <Button onClick={()=> { router.go(`/companies/${params.id}/edit`); }}>
            edit
          </Button>
          <br />
          <Typography use="headline3">{name}</Typography>
          <br />
          <b>Contact: </b>{contact}<br />
          <b>Admin Domains</b>
          <br />
          <List>
            {
              adminDomains.map((el, id)=> (
                <ListItem key={id}>
                  {el}<br />
                </ListItem>
              ))
            }
          </List>
          <b>Workers</b><br />
          <List>
            {workers.map((el, id)=> (
              <ListItem key={id}>
                {el.account.personas[0].displayName}<br />
              </ListItem>
            ))
            }
          </List>
        </Elevation>
      </div>
    );
  }

  getCompany = async ()=> {
    const {client} = this.props;
    const {id} = this.props.params;
    const variables = {id};

    const result = await client.query({
      query: getCompany,
      variables
    });

    return result.data.getCompany;
  }
}

CompanyPage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default withApollo(CompanyPage);
