import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Promise from 'bluebird';
import uuidv4 from 'uuid/v4';
import {LinearProgress} from '@rmwc/linear-progress';
import {Icon} from '@rmwc/icon';

import firebase from '../firebase';
import Enums from '../../Enums.json';
import './ImageUploader.scss';

class ImageUploaderPlaceholder extends Component {
  constructor (props) {
    super(props);
    this.state = {
      status: 'Loading',
      uploadProgress: 0,
      loadProgress: 0,
      src: null,
      width: null,
      height: null,
      extension: null,
      uuid: null
    };
  }

  render () {
    const {status} = this.state;
    const $body = this[`render${status}`]();
    return (
      <div className="ImageUploaderPlaceholder">
        {$body}
      </div>
    );
  }

  uploadProgress () {
    return this.state.uploadProgress;
  }

  loaded () {
    const {status} = this.state;
    return (status === 'Loading');
  }

  renderLoading () {
    const {loadProgress} = this.state;
    return (
      <div className="ImageUploaderPlaceholderLoading">
        {this.renderProgressOverlay(loadProgress)}
      </div>
    );
  }

  renderLoaded () {
    // TODO: handle cropping so aspect isn't skewed
    return (
      <div className="ImageUploaderPlaceholderLoaded">
        {this.renderThumbnail({remove: true})}
      </div>
    );
  }

  renderUploading () {
    const {uploadProgress} = this.state;
    return (
      <div
        className="ImageUploaderPlaceholderUploading"
      >
        {this.renderThumbnail()}
        {this.renderProgressOverlay(uploadProgress)}
      </div>
    );
  }

  renderProgressOverlay (progress) {
    return (
      <div className="ImageUploaderPlaceholderProgress">
        <LinearProgress progress={progress}></LinearProgress>
      </div>
    );
  }

  renderUploaded () {
    return (
      <div className="ImageUploaderPlaceholderUploaded">
        {this.renderThumbnail({remove: true, uploaded: true})}
      </div>
    );
  }

  renderThumbnail ({remove = false, uploaded = false} = {}) {
    const {src} = this.state;
    let $remove = '';
    if (remove) {
      $remove = (
        <Icon
          onClick={this.remove}
          className="ImageUploaderPlaceholderRemove"
          strategy="ligature"
          icon="delete_forever"
        />
      );
    }

    let $cloud = '';
    if (uploaded) {
      $cloud = (
        <Icon
          className="ImageUploaderPlaceholderCloud"
          strategy="ligature"
          icon="cloud_done"
        />
      );
    }

    return (
      <div className="ImageUploaderPlaceholderThumbnail">
        <img
          src={src}
          alt="Uploaded thumbnail"
        />
        {$remove}
        {$cloud}
      </div>
    );
  }

  remove = (event)=> {
    event.preventDefault();
    this.props.onRemove();
  };

  componentDidMount () {
    const {file} = this.props;
    const extension = file.type.split('/')[1];

    const reader = new window.FileReader();
    reader.onProgress = (event)=> {
      const {lengthComputable, total, loaded} = event;
      if (lengthComputable) {
        const loadProgress = (loaded / total);
        this.setState({loadProgress});
      }
    };

    function getContentType (result) {
      return result.split(',')[0].split(':')[1].split(';')[0];
    }

    reader.onloadend = ()=> {
      const src = reader.result;
      const contentType = getContentType(src);
      const image = new window.Image();
      image.src = src;
      image.onload = ()=> {
        const {width, height} = image;
        const uuid = uuidv4();
        this.setState({
          status: 'Loaded',
          loadProgress: 1,
          src,
          contentType,
          width,
          height,
          extension,
          uuid
        });
      };
    };
    reader.readAsDataURL(file);
  }

  storageRef (path) {
    let {bucket} = this.props;
    if (!bucket) {
      const env = process.env.REACT_APP_TRIPP_ENV;
      bucket = `tripp_${env}_images`;
    }
    return firebase.app().storage(bucket).ref().child(path);
  }

  upload = ()=> {
    return new Promise(async (resolve, reject)=> {
      try {
        this.setState({status: 'Uploading'});

        const {file} = this.props;
        const {
          uuid,
          extension,
          width,
          height,
          contentType
        } = this.state;
        const path = `${uuid}/Original.${extension}`;
        const ref = this.storageRef(path);
        const type = Enums.MediaType.Image;

        const cacheControl = 'private,max-age=2592000';
        const metadata = {contentType, cacheControl};
        const upload = ref.put(file, metadata);
        upload.on('state_changed', (snapshot)=> {
          const {bytesTransferred, totalBytes} = snapshot;
          const uploadProgress = (bytesTransferred / totalBytes);
          this.setState({uploadProgress});
        });
        await upload;

        this.setState({
          status: 'Uploaded',
          uploadProgress: 1
        });
        resolve({uuid, extension, width, height, type});
      } catch (error) {
        reject(error);
      }
    });
  }
}

ImageUploaderPlaceholder.propTypes = {
  file: PropTypes.object.isRequired,
  onRemove: PropTypes.func.isRequired,
  bucket: PropTypes.string
};

export default ImageUploaderPlaceholder;
