import {Router} from 'groutcho';

import {session, NotFoundPage} from '@tripp/shared';
import {SignInPage} from '../Authentication';
import {HomePage} from '../Home';
import {ContentPage} from '../Content';
import {
  AccountSearch,
  AccountEntitlement
} from '../Account'
import {
  ExperienceListPage,
  ExperiencesSchedulePage,
  ExperiencePage,
  ExperienceEditPage,
  SchedulePage,
  ScheduleUploaderPage
} from '../Experience';
import {StatisticsPage} from '../Statistics';
import {
  CatalogPage,
  CatalogEntryPage,
  CatalogEntryCreatePage,
  CatalogEntryEditPage
} from '../Catalog';
import {
  CompanyListPage,
  CompanyPage,
  CompanyCreatePage,
  CompanyEditPage
} from '../Company';
import {
  MultiUseListPage,
  MultiUsePage
} from '../MultiUse';
import {
  DevicePage,
  DeviceListPage,
  DevicePairingPage,
  DevicePairingMessagesPage
} from '../Device';

const routes = {
  Home: {
    pattern: '/',
    page: HomePage,
    session: true,
    admin: true
  },
  AccountEntitlement: {
    pattern: '/account/entitlement/add',
    page: AccountEntitlement,
    session: true,
    admin: true
  },
  AccountSearch: {
    pattern: '/account/search',
    page: AccountSearch,
    session: true,
    admin: true
  },
  ContentPage: {
    pattern: '/content/video/add',
    page: ContentPage,
    session: true,
    admin: true
  },
  ExperienceList: {
    pattern: '/experiences',
    page: ExperienceListPage,
    session: true,
    admin: true
  },
  ExperiencesSchedule: {
    pattern: '/experiences/schedule',
    page: ExperiencesSchedulePage,
    session: true,
    admin: true
  },
  Experience: {
    pattern: '/experiences/:id',
    page: ExperiencePage,
    session: true,
    admin: true
  },
  ExperienceEdit: {
    pattern: '/experiences/:id/edit',
    page: ExperienceEditPage,
    session: true,
    admin: true
  },
  Schedule: {
    pattern: '/schedule',
    page: SchedulePage,
    session: true,
    admin: true
  },
  ScheduleUploader: {
    pattern: '/schedule/uploader',
    page: ScheduleUploaderPage,
    session: true,
    admin: true
  },
  Statistics: {
    pattern: '/statistics',
    page: StatisticsPage,
    session: true,
    admin: true
  },
  Catalog: {
    pattern: '/catalog',
    page: CatalogPage,
    session: true,
    admin: true
  },
  CatalogEntryCreate: {
    pattern: '/catalog/create',
    page: CatalogEntryCreatePage,
    session: true,
    admin: true
  },
  CatalogEntry: {
    pattern: '/catalog/:id',
    page: CatalogEntryPage,
    session: true,
    admin: true
  },
  CatalogEntryEditPage: {
    pattern: '/catalog/:id/edit',
    page: CatalogEntryEditPage,
    session: true,
    admin: true
  },
  Companies: {
    pattern: '/companies',
    page: CompanyListPage,
    session: true,
    admin: true
  },
  MultiUseLicenses: {
    pattern: '/multiuselicenses',
    page: MultiUseListPage,
    session: true,
    admin: true
  },
  CompanyCreate: {
    pattern: '/companies/create',
    page: CompanyCreatePage,
    session: true,
    admin: true
  },
  Company: {
    pattern: '/companies/:id',
    page: CompanyPage,
    session: true,
    admin: true
  },
  CompanyEdit: {
    pattern: '/companies/:id/edit',
    page: CompanyEditPage,
    session: true,
    admin: true
  },
  Devices: {
    pattern: '/devices',
    page: DeviceListPage,
    session: true,
    admin: true
  },
  DevicePairing: {
    pattern: '/devices/pairing/:localId?',
    page: DevicePairingPage,
    session: true,
    admin: true
  },
  DevicePairingMessages: {
    pattern: '/devices/pairing/messages/:deviceId',
    page: DevicePairingMessagesPage,
    session: true,
    admin: true
  },
  Device: {
    pattern: '/devices/:id',
    page: DevicePage,
    session: true,
    admin: true
  },
  NotFound: {
    pattern: '/404',
    page: NotFoundPage
  },
  SignIn: {
    pattern: '/signin',
    page: SignInPage,
    session: false
  }
};

const router = new Router({
  routes,
  redirects: {
    NotFound: (match)=> {
      return match ? false : 'NotFound';
    },
    Session: ({route})=> {
      const hasSession = (route.session !== undefined);
      const requireSession = (hasSession && route.session);
      const requireNoSession = (hasSession && !route.session);
      const signedIn = session.signedIn();

      if (requireSession && !signedIn) {
        return 'SignIn';
      }
      if (requireNoSession && signedIn) {
        return 'Home';
      }
      return false;
    },
    Admin: ({route})=> {
      if (!session.signedIn()) {
        return false;
      }
      const {admin} = route;
      const shouldRedirect = (admin && !session.hasRole('Admin'));
      if (!shouldRedirect) {
        return false;
      }
      // if they aren't an admin, redirect to the user site
      session.signOut();
      const {host} = window.location;
      let url;
      if (host.match(/^localhost/)) {
        url = 'http://localhost:3000';
      } else {
        const newHost = host.replace(/^admin\./, '');
        url = `https://${newHost}`;
      }
      return url;
    },
    EmailNotVerified: ({route})=> {
      if (!session.signedIn()) {
        return false;
      }
      const {user} = session;
      const notVerified = user && !user.emailVerified;
      const shouldRedirect = route.session && notVerified;
      return shouldRedirect ? 'SignIn' : false;
    }
  }
});

export default router;
