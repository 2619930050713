import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';
import _ from 'lodash';
import {Typography} from '@rmwc/typography';

import {listAssetBundles, listAssetBundleDocRefs} from './StatisticsQueries';

import './Statistics.scss';

function toObj (arr) {
  return arr.reduce((result, {hash, name})=> {
    result[name] = hash;
    return result;
  }, {});
}

class StatisticsPage extends Component {
  constructor (props) {
    super(props);

    this.state = {
      firestore: [],
      storage: [],
      hashMismatches: [],
      loading: true
    };
  }

  async componentDidMount () {
    const {loading} = this.state;

    if (loading) {
      const firestore = await this.queryAssetBundles();
      const storage = await this.queryAssetBundleDocRefs();

      const fsObj = toObj(firestore);

      const hashMismatches = firestore.filter(({hash, name})=> {
        return hash === fsObj[name];
      });

      this.setState({
        storage: _.uniqBy(storage, 'name'),
        loading: false,
        firestore,
        hashMismatches
      });
    }
  }

  render () {
    const {
      loading,
      firestore,
      storage,
      hashMismatches
    } = this.state;

    if (loading) {
      return (
        <div>Loading</div>
      );
    }

    let $mismatches = '';
    if (hashMismatches > 0) {
      $mismatches = (
        <ul>Mismatched Hashes:
          {hashMismatches.map((el)=> <li key={el.name}>{el.name}</li>)}
        </ul>
      );
    }

    return (
      <div>
        <Typography use="headline6">Server Statistics Page</Typography>
        <p>AssetBundles in Firestore: {firestore.length}</p>
        <p>AssetBundles in Storage: {storage.length}</p>
        <p>AssetBundles with hash mismatches: {hashMismatches.length}</p>
        {$mismatches}
      </div>
    );
  }

  queryAssetBundleDocRefs = async ()=> {
    const {client} = this.props;

    const result = await client.query({
      query: listAssetBundleDocRefs
    });

    return result.data.listAssetBundleDocRefs
      .map((el)=> {
        const [name, hash] = _.split(el, '/');
        return {name, hash};
      });
  }

  queryAssetBundles = async ()=> {
    const {client} = this.props;

    const result = await client.query({
      query: listAssetBundles,
      variables: {platform: 'Android'}
    });

    return result.data.listAssetBundles;
  }
}

StatisticsPage.propTypes = {
  client: PropTypes.object.isRequired
};

export default withApollo(StatisticsPage);
