import React from 'react';
import PropTypes from 'prop-types';
import pusherSubscribe from './pusherSubscribe';

class PusherMessages extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      messages: []
    };
  }

  render () {
    const {messages} = this.state;
    const $messages = JSON.stringify(messages);
    return (
      <div>
        <pre>
          {$messages}
        </pre>
      </div>
    );
  }

  componentDidMount () {
    const {event, channel} = this.props;
    const {messages} = this.state;
    const callback = (message)=> {
      messages.push(message);
      this.setState({messages});
    };
    pusherSubscribe({event, channel, callback});
  }
}

PusherMessages.propTypes = {
  event: PropTypes.string.isRequired,
  channel: PropTypes.string.isRequired
};

export default PusherMessages;
