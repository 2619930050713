import React from 'react';
import PropTypes from 'prop-types';
import {ApolloProvider} from 'react-apollo';
import _ from 'lodash';
import {
  TopAppBar,
  TopAppBarRow,
  TopAppBarSection,
  TopAppBarNavigationIcon,
  //TopAppBarActionItem,
  TopAppBarTitle
} from '@rmwc/top-app-bar';
import {MenuItem} from '@rmwc/menu';
import {
  Drawer,
  DrawerAppContent,
  DrawerContent
} from '@rmwc/drawer';
import {Icon} from '@rmwc/icon';
import {Button} from '@rmwc/button';
import {Typography} from '@rmwc/typography';

import {session} from '../Session';
import client from '../client';
import Submenu from '../Submenu';
import SessionLoadingPage from '../SessionLoadingPage';
import './Application.scss';

function pushState (pathname) {
  // TODO: what about dem query strings and what not
  if (pathname !== window.location.pathname) {
    window.history.pushState({}, '', pathname);
  }
}

class Application extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      pathname: props.pathname,
      loading: true,
      menuOpen: false
    };

    const handlerNames = [
      'popstate',
      'handleSignOut',
      'gotoProfile',
      'gotoHome',
      'renderMenuItem'
    ];
    for (const handlerName of handlerNames) {
      this[handlerName] = this[handlerName].bind(this);
    }
  }

  async componentDidMount () {
    await session.listen((user)=> {
      if (user) {
        const path = session.getAuthRedirectPath();
        if (path) {
          const {router} = this.props;
          router.go(path);
        }
      }
      this.setState({loading: false});
    });

    const {router} = this.props;
    router.onChange((pathname)=> {
      if (pathname !== this.state.pathname) {
        this.setState({pathname});
      }
      pushState(pathname);
    });

    window.addEventListener('popstate', this.popstate);
  }

  componentWillUnmount () {
    session.stopListening();
    window.addEventListener('popstate', this.popstate);
  }

  popstate () {
    const {pathname} = window.location;
    this.setState({pathname});
  }

  render () {
    const {title} = this.props;
    const {loading} = this.state;

    if (loading) {
      const message = `Loading ${title}`;
      return <SessionLoadingPage message={message}/>;
    }

    return (
      <ApolloProvider client={client}>
        <div className="Application">
          {this.renderPage()}
        </div>
      </ApolloProvider>
    );
  }

  renderPage () {
    const {router} = this.props;
    const {pathname} = this.state;

    const match = router.match(pathname);
    const {params, redirect, route} = match;
    if (redirect) {
      const {url} = match;
      if (url.match(/^https?:\/\//)) {
        window.location = url;
      } else {
        pushState(url);
      }
    }
    const Page = route.page;
    const $page = <Page {...{router, route, params}} />;

    const hideTopBar = Page.hideTopBar && Page.hideTopBar();
    return hideTopBar ? $page : this.renderPageWithTopBar($page);
  }

  renderPageWithTopBar ($page) {
    const {title} = this.props;
    const {menuOpen} = this.state;

    // TODO: handle showing avatar and switching personas in right nav menu
    // const {personas} = session.account;
    // const {username, displayName, avatar} = personas[0];
    // let $avatar = '';
    // if (avatar) {
    //   $avatar = (
    //     <img
    //       src={avatar.url}
    //       style={{
    //         width: `${avatar.width}px`,
    //         height: `${avatar.height}px`
    //       }}
    //     />
    //   );
    // }

    let $signout = '';
    let $navIcon = '';
    if (session.signedIn()) {
      const email = _.get(session, 'account.email');
      $signout = (
        <div>
          <Typography
            use="subtitle2"
            className="HeaderSignedInAs"
            onClick={this.gotoProfile}
          >
            {email}
          </Typography>
          <Button
            unelevated
            onClick={this.handleSignOut}
          >
            Signout
          </Button>
        </div>
      );

      $navIcon = (
        <TopAppBarNavigationIcon
          style={{color: 'inherit', alignSelf: 'center'}}
          onClick={()=> this.setState({menuOpen: !menuOpen})}
          icon="menu"
        />
      );
    }

    const menu = this.props.menu();
    const $drawer = menu.map(this.renderMenuItem);

    return (
      <>
        <TopAppBar fixed>
          <TopAppBarRow>
            <TopAppBarSection alignStart>
              {$navIcon}
              <TopAppBarTitle
                onClick={this.gotoHome}
              >
                {title}
              </TopAppBarTitle>
            </TopAppBarSection>
            <TopAppBarSection alignEnd>
              {$signout}
            </TopAppBarSection>
          </TopAppBarRow>
        </TopAppBar>
        <div className="Content">
          <Drawer
            dismissible
            open={menuOpen}
            onClose={()=> this.setState({menuOpen: false})}
          >
            <DrawerContent>
              {$drawer}
            </DrawerContent>
          </Drawer>
          <DrawerAppContent>
            {$page}
          </DrawerAppContent>
        </div>
      </>
    );
  }

  renderMenuItem ({label, url, submenu}) {
    const leaf = ({label, url, icon})=> {
      const $icon = icon ? <Icon icon={icon}/> : '';
      return (
        <MenuItem
          key={label}
          onClick={(evt)=> {
            evt.preventDefault();
            this.setState({
              menuOpen: false
            });
            this.props.router.go(url);
          }}
        >
          {$icon}
          {label}
        </MenuItem>
      );
    };

    if (submenu) {
      return (
        <Submenu label={label} key={label}>
          {submenu.map(leaf)}
        </Submenu>
      );
    }

    return leaf({label, url});
  }

  gotoProfile (evt) {
    evt.preventDefault();
    this.props.router.go('/profile');
  }

  gotoHome (evt) {
    evt.preventDefault();
    this.setState({menuOpen: false});
    this.props.router.go('/');
  }

  async handleSignOut () {
    await session.signOut();
    this.props.router.go('/signin');
  }
}

Application.propTypes = {
  pathname: PropTypes.string.isRequired,
  router: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  menu: PropTypes.func.isRequired
};

export default Application;
