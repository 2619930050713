import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Icon} from '@rmwc/icon';
import {Button} from '@rmwc/button';
import {TextField, TextFieldHelperText} from '@rmwc/textfield';
import {Elevation} from '@rmwc/elevation';
import {List, ListItem} from '@rmwc/list';
import {Fab} from '@rmwc/fab';
import {Radio} from '@rmwc/radio';
import {Typography} from '@rmwc/typography';

import {
  addCompanyWorker,
  deleteCompanyWorker,
  updateCompany,
  getCompany,
  getAccountByEmail
} from './CompanyQueries';

import './Company.scss';

const faState = {
  SETEMAIL: 0,
  SETWORKER: 1,
  SEAT: 2,
  ADMIN: 3,
  SUBMITTED: 4
};

const workerType = {
  ADMIN: 'Admin',
  SEAT: 'Seat'
};

class CompanyEditPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      loading: true,
      company: {name: ''}
    };
  }

  async componentDidMount () {
    if (this.state.loading) {
      try {
        const {id} = this.props.params;
        const company = await this.getCompany(id);
        const adminDomainVal = company.adminDomains.map(()=> true);

        this.setState({
          company,
          loading: false,
          newWorker: false,
          adminDomainVal
        });
      } catch (e) {
        console.log('Something went wrong', e);
        throw e;
      }
    }
  }

  render () {
    const {router, params} = this.props;
    const {loading, newWorker} = this.state;
    if (loading) {
      return <h1>Loading</h1>;
    }
    const {name,
      contact,
      adminDomains,
      workers
    } = this.state.company;

    let $findAccount = '';
    if (newWorker) {
      $findAccount = (
        <FindAccount
          newWorkerToggle={this.newWorkerToggle}
          status={faState.SETEMAIL}
          client={this.props.client}
          id={this.state.company.id}
          deleteCompanyWorker={this.deleteCompanyWorker}
        />
      );
    }

    return (
      <div className="Page CompanyPage Centered">
        <Elevation z={3} className="Centered">
          <Button onClick={()=> { router.go(`/companies/${params.id}`); }}>
            back
          </Button>
          <CompanyField
            setValue={this.setName}
            field='name'
            value={name}
            updateCompany={this.updateCompany}
            header={true}
          />
          <br />

          <CompanyField
            setValue={this.setContact}
            field='contact'
            value={contact}
            updateCompany={this.updateCompany}
            header={false}
          />
          <br />

          <AdminDomainFields
            adminDomains={adminDomains}
            addDomain={this.addDomain}
            setDomain={this.setDomain}
            deleteDomain={this.deleteDomain}
            updateCompany={this.updateCompany}
            validationArr={this.adminDomainVal}
            cancelDomain={this.cancelDomain}
          />

          <Typography use="subtitle1">
            Workers
            <Fab mini onClick={this.newWorkerToggle}>add</Fab>
          </Typography>
          <List>
            {workers.map((el)=> (
              <ListItem key={el.id}>
                {el.account.personas[0].displayName}<br />
                <Fab mini onClick={()=> this.deleteCompanyWorker(el.id)}>delete</Fab>
              </ListItem>
            ))
            }
          </List>
          {$findAccount}
        </Elevation>
      </div>
    );
  }

  newWorkerToggle = ()=> {
    const {newWorker} = this.state;
    this.setState({newWorker: !newWorker});
  }

  // Getters/Setters

  addDomain = ()=> {
    const company = {...this.state.company};
    company.adminDomains = company.adminDomains.concat(['']);
    this.setState({company});
  }

  setDomain = (id)=> (e)=> {
    const value = e.target ? e.target.value : e;
    const company = {...this.state.company};
    const domains = company.adminDomains.slice();
    domains[id] = value;
    company.adminDomains = domains;
    this.setState({company});
  }

  deleteDomain = (id)=> ()=> {
    const company = {...this.state.company};
    const domains = company.adminDomains.filter((el, idx)=> idx !== id);
    company.adminDomains = domains;
    this.setState({company}, this.updateCompany);
  }

  cancelDomain = (id)=> ()=> {
    const company = {...this.state.company};
    const domains = company.adminDomains.filter((el, idx)=> idx !== id);
    company.adminDomains = domains;
    this.setState({company});
  }

  setName = (e)=> {
    const company = {...this.state.company};
    const name = e.target.value;
    company.name = name;
    this.setState({company});
  }

  setContact = (e)=> {
    const company = {...this.state.company};
    const contact = e.target.value;
    company.contact = contact;
    this.setState({company});
  }

  // GraphQL Queries/Mutations

  getCompany = async ()=> {
    const {client} = this.props;
    const {id} = this.props.params;
    const variables = {id};

    const result = await client.query({
      query: getCompany,
      variables
    });

    return result.data.getCompany;
  }

  updateCompany = async ()=> {
    const {client} = this.props;
    const {id} = this.props.params;
    const input = {
      name: this.state.company.name,
      contact: this.state.company.contact,
      adminDomains: this.state.company.adminDomains
    };
    const variables = {id, input};
    const mutation = updateCompany;
    try {
      await client.mutate({
        mutation,
        variables,
        refetchQueries: [{query: getCompany, variables: {id}}]
      });
    } catch (error) {
      console.error('Error updating company', error);
      throw Error;
    }
  }

  deleteCompanyWorker = async (id)=> {
    const {client} = this.props;
    const variables = {id};
    const mutation = deleteCompanyWorker;
    try {
      const companyId = this.props.params.id;
      await client.mutate({
        mutation,
        variables,
        refetchQueries: [{query: getCompany, variables: {id: companyId}}]
      });
    } catch (error) {
      console.error('Error deleting worker', error);
      throw Error;
    }
  }
}

class CompanyField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      edit: false,
      header: props.header,
      deletable: props.delete
    };
  }

  render () {
    const {
      edit,
      deletable,
      header,
      field,
      value,
      setValue
    } = this.state;

    if (!edit) {
      if (header) {
        return (
          <div>
            <Typography use="headline3">{this.props.value}</Typography>
            <Fab mini onClick={this.toggleEdit}>edit</Fab>
            {deletable && <Fab mini onClick={this.deleteField}>delete</Fab>}
          </div>
        );
      }
      return (
        <div>
          <b>{this.props.field}:</b> {this.props.value}
          <Fab mini onClick={this.toggleEdit}>edit</Fab>
          {deletable && <Fab mini onClick={this.deleteField}>delete</Fab>}
        </div>
      );
    }
    return (
      <div>
        <TextField
          label={field}
          value={value}
          onChange={setValue}
        />
        <Fab mini onClick={this.submitField}>check</Fab>
        <Fab mini onClick={this.toggleEdit}>cancel</Fab>
      </div>
    );
  }

  toggleEdit = ()=> {
    const {edit} = this.state;

    this.setState({edit: !edit});
  }

  submitField = ()=> {
    this.toggleEdit();
    return this.props.updateCompany();
  }
}

class AdminDomainFields extends Component {
  render () {
    const {
      addDomain,
      adminDomains,
      setDomain,
      deleteDomain,
      updateCompany,
      cancelDomain
    } = this.props;

    return (
      <div>
        <Typography use='subtitle1'>
          Admin Domains
          <Fab mini onClick={addDomain}>add</Fab>
        </Typography>

        <List>
          {adminDomains.map((el, id)=> (
            <ListItem key={id}>
              <AdminDomainField
                field={el}
                setDomain={setDomain(id)}
                deleteDomain={deleteDomain(id)}
                updateCompany={updateCompany}
                cancelDomain={cancelDomain(id)}
              />
            </ListItem>

          ))
          }
        </List>
      </div>
    );
  }
}

class AdminDomainField extends Component {
  constructor (props) {
    super(props);
    this.state = {
      edit: !props.field,
      validation: true
    };
  }

  componentDidMount () {
    this.validateDomain(this.props.field);
    this.setState({oldValue: this.props.field});
  }

  render () {
    const {edit, validation} = this.state;
    const {
      deleteDomain,
      field,
      setDomain
    } = this.props;
    if (!edit) {
      return (
        <div>
          {this.props.field}
          <Fab mini onClick={this.toggleEdit}>edit</Fab>
          <Fab mini onClick={deleteDomain}>delete</Fab>
        </div>
      );
    }
    return (
      <div>
        <TextField label="adminDomain"
          value={field}
          onChange={setDomain}
          invalid={!validation}
          onBlur={this.validateDomain}
        />
        <Fab mini onClick={this.handleSubmit}>check</Fab>
        <Fab mini onClick={this.cancelDomain}>close</Fab>
        <TextFieldHelperText validationMsg>Invalid Email Address.</TextFieldHelperText>
      </div>
    );
  }

  validateDomain = (e)=> {
    const domain = e.target ? e.target.value : e;
    const re = new RegExp(/^((?:(?:(?:\w[.\-+]?)*)\w)+)((?:(?:(?:\w[.\-+]?){0,62})\w)+)\.(\w{2,6})$/);
    const validation = !!domain.match(re);
    this.setState({validation});
  }

  // Getters/Setters

  toggleEdit = ()=> {
    const {edit} = this.state;
    this.setState({edit: !edit});
  }

  cancelDomain = ()=> {
    const {oldValue} = this.state;
    if (oldValue === '') {
      this.props.cancelDomain();
      this.toggleEdit();
      return;
    }

    this.props.setDomain(oldValue);
    this.toggleEdit();
  }

  // GraphQL Queries/Mutation

  handleSubmit = ()=> {
    this.validateDomain(this.props.field);
    if (!this.state.validation) {
      return;
    }
    this.toggleEdit();
    this.setState({validation: true}, this.props.updateCompany);
  }
}

class FindAccount extends Component {
  constructor (props) {
    super(props);
    this.state = {
      email: '',
      emailValidation: true,
      accountValid: false,
      emailValText: 'Invalid email address.',
      input: {
        type: 'Admin',
        accountId: '',
        seatGroup: '',
        seatLocation: '',
        adminType: ''
      }
    };
  }

  render () {
    const {
      email,
      emailValidation,
      emailValText,
      input,
      accountValid
    } = this.state;

    let $seatForm = '';
    if (input.type === workerType.SEAT) {
      $seatForm = (
        <div>
          <TextField
            value={this.state.input.seatGroup}
            onChange={this.setSeatGroup}
            label='Seat Group'
          />
          <TextField
            value={this.state.input.seatLocation}
            onChange={this.setSeatLocation}
            label='Seat Location'
          />
        </div>
      );
    } else {
      $seatForm = (
        <div>
          <Radio
            value='Owner'
            checked={input.adminType === 'Owner'}
            onChange={this.setAdminType}>
            Owner
          </Radio>
          <Radio
            value='Editor'
            checked={input.adminType === 'Editor'}
            onChange={this.setAdminType}>
            Editor
          </Radio>
        </div>
      );
    }

    return (
      <div>
        <Typography use="subtitle1">
          New Worker
        </Typography>
        <div>
          <TextField
            value={email}
            invalid={!emailValidation}
            onChange={this.setEmail}
            onBlur={this.submitEmail}
            label="Email"
        />
        {accountValid && <Icon icon="check" strategy="ligature"/>}
        <TextFieldHelperText validationMsg>{emailValText}</TextFieldHelperText>
        </div>
        <div>
          <Radio
            value='Admin'
            checked={input.type === workerType.ADMIN}
            onChange={this.setWorkerType}>
            {workerType.ADMIN}
          </Radio>
          <Radio
            value='Seat'
            checked={input.type === workerType.SEAT}
            onChange={this.setWorkerType}>
            {workerType.SEAT}
          </Radio>
        </div>

        {$seatForm}

        <Button onClick={this.addCompanyWorker}>Submit</Button>
        <Button onClick={this.clearForm}>Cancel</Button>
      </div>
    );
  }

  submitEmail = ()=> {
    const {email} = this.state;
    const emailValidation_ = this.validateEmail(email);

    if (emailValidation_) {
      return this.setState({
        emailValidation: emailValidation_,
        accountValid: false
      }, this.getAccountByEmail);
    }
    return this.setState({
      emailValidation: emailValidation_,
      emailValText: 'Invalid email address.',
      accountValid: false
    });
  }

  validateEmail = (email)=> {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

  clearForm = ()=> {
    const input = {accountId: '', type: 'admin'};
    this.setState({input}, this.props.newWorkerToggle);
  }

  setAdminType = (e)=> {
    const input = {...this.state.input};
    input.adminType = e.target.value;

    this.setState({input});
  }

  setSeatLocation = (e)=> {
    const {input} = this.state;
    const newLocation = e.target.value;
    input.seatLocation = newLocation;
    this.setState({input});
  }

  setSeatGroup = (e)=> {
    const {input} = this.state;
    const newGroup = e.target.value;
    input.seatGroup = newGroup;
    this.setState({input});
  }

  setWorkerDetails = ()=> {
    const {type} = this.state.input;
    let {status} = this.state;

    if (type === workerType.SEAT) {
      status = faState.SEAT;
    } else {
      status = faState.ADMIN;
    }

    this.setState({status});
  }

  setWorkerType = (e)=> {
    const input = {...this.state.input};
    input.type = e.target.value;

    this.setState({input});
  }

  setStatus = (val)=> {
    this.setState({status: val});
  }

  setEmail = (e)=> {
    const newEmail = e.target.value;
    this.setState({email: newEmail});
  }

  // GraphQL Queries/Mutations

  addCompanyWorker = async ()=> {
    if (!this.state.accountValid) {
      console.log('Invalid account.');
      return;
    }

    const {client, id} = this.props;
    const {input} = this.state;

    if (input.type === 'Admin') {
      delete input.seatGroup;
      delete input.seatLocation;
      if (!input.adminType) {
        console.log('Missing adminType.');
        return;
      }
    } else {
      delete input.adminType;
      if (!input.seatGroup || !input.seatLocation) {
        console.log('Missing seat field(s).');
        return;
      }
    }
    const variables = {id, input};
    const mutation = addCompanyWorker;
    try {
      await client.mutate({
        mutation,
        variables,
        refetchQueries: [{query: getCompany, variables: {id}}]
      });
      this.clearForm();
    } catch (error) {
      console.error('Error adding worker');
      throw Error;
    }
  }

  getAccountByEmail = async ()=> {
    const {client} = this.props;
    const {email} = this.state;

    const variables = {email};
    const query = getAccountByEmail;
    try {
      const result = await client.query({
        query,
        variables
      });

      if (result.data.getAccountByEmail !== null) {
        const input = {...this.state.input};
        const {id} = result.data.getAccountByEmail;
        input.accountId = id;
        this.setState({input, accountValid: true});
        return;
      }
      this.setState({
        emailValidation: false,
        emailValText: 'No account found.'
      });
    } catch (error) {
      console.error('Error fetching account', error);
      throw Error;
    }
  }
}

CompanyEditPage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

CompanyField.propTypes = {
  header: PropTypes.bool,
  delete: PropTypes.bool,
  value: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  updateCompany: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired
};

AdminDomainFields.propTypes = {
  addDomain: PropTypes.func.isRequired,
  adminDomains: PropTypes.array.isRequired,
  updateCompany: PropTypes.func.isRequired,
  setDomain: PropTypes.func.isRequired,
  deleteDomain: PropTypes.func.isRequired,
  cancelDomain: PropTypes.func.isRequired
};

AdminDomainField.propTypes = {
  deleteDomain: PropTypes.func.isRequired,
  field: PropTypes.string.isRequired,
  edit: PropTypes.bool,
  setDomain: PropTypes.func.isRequired,
  updateCompany: PropTypes.func.isRequired,
  cancelDomain: PropTypes.func.isRequired
};

FindAccount.propTypes = {
  newWorkerToggle: PropTypes.func.isRequired,
  client: PropTypes.object.isRequired,
  id: PropTypes.string.isRequired
};

export default withApollo(CompanyEditPage);
