import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';
import uuidv4 from 'uuid/v4';
import _ from 'lodash';
import {Select} from '@rmwc/select';
import {TextField} from '@rmwc/textfield';
import {Typography} from '@rmwc/typography';

import {
  AsyncButton,
  Enums,
  enumToOptions,
  Page,
  session
} from '@tripp/shared';
import {
  findOrCreateDeviceAndPairing,
  getDeviceByLocalId,
  pairDevice,
  unpairDevice
} from './DeviceQueries';

import './Device.scss';

class DevicePairingPage extends Component {
  state = {
    deviceInput: {
      model: Enums.DeviceModel.LenovoMirage,
      type: Enums.DeviceType.Mobile
    },
    code: ''
  }

  getLocalId () {
    return this.props.params.localId;
  }

  render () {
    const localId = this.getLocalId();
    if (!localId) {
      const {router} = this.props;
      const uuid = uuidv4();
      router.go(`/devices/pairing/${uuid}`);
      return 'generating localId...';
    }

    return (
      <Page
        className="DevicePairingPage"
        query={getDeviceByLocalId}
        variables={{localId}}
      >
        {({getDeviceByLocalId: device})=> {
          // this way of passing mutate as a parameter sucks..
          // need to find a better way of doing this so i don't hate
          // react and apollo so much
          const $deviceDetails = this.renderDeviceDetails(device);
          const $pairings = this.renderPairings(device);
          const $pairingStart = this.renderPairingStart(device);
          const $pairingFinish = this.renderPairingFinish(device);
          return (
            <div className="Device">
              <h1>
                Device {localId}
              </h1>
              {$deviceDetails}
              {$pairings}
              {$pairingFinish}
              {$pairingStart}
            </div>
          );
        }}
      </Page>
    );
  }

  renderDeviceDetails (device) {
    if (!device) {
      return '';
    }
    const {id, model, type} = device;
    return (
      <div className="DeviceDetails">
        <div>id: {id}</div>
        <div>model: {model}</div>
        <div>type: {type}</div>
      </div>
    );
  }

  renderPairings (device) {
    if (!device) {
      return '';
    }
    const {pairings} = device;
    const $pairings = pairings.map(this.renderPairing);
    return (
      <div className="DevicePairings">
        <h2>{pairings.length} pairings</h2>
        {$pairings}
      </div>
    );
  }

  renderPairing = ({id: pairingId, status, code, expiresAt, account})=> {
    let $account = '';
    if (account) {
      const {id: accountId, email} = account;
      $account = (
        <div>Account: {email} ({accountId})</div>
      );
    }
    let $unpair = '';
    if (status === 'Paired') {
      $unpair = (
        <AsyncButton
          action={this.unpairDevice(pairingId)}
        >
          Delete pairing
        </AsyncButton>
      );
    }

    return (
      <div className="DevicePairing" key={pairingId}>
        <div>Status: {status}</div>
        <div>Code: {code}</div>
        <div>Expires At: {expiresAt}</div>
        {$account}
        {$unpair}
      </div>
    );
  };

  renderPairingFinish (device) {
    if (!device) {
      return '';
    }

    const {code} = this.state;

    return (
      <div className="PairingFinish">
        <a
          href={`/devices/pairing/messages/${device.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          open pusher messages in separate tab
        </a>
        <div>
          <TextField
            key="pairingCode"
            label="Pairing code"
            onChange={this.handleCodeChange}
            value={code}
          />
          <AsyncButton
            action={this.pairDevice}
          >
            Pair device
          </AsyncButton>
        </div>
      </div>
    );
  }

  renderPairingStart (device) {
    let $selects = '';
    if (!device) {
      $selects = this.renderSelects();
    }
    return (
      <div className="PairingStart">
        <Typography use="headline6">
          Start new pairing
        </Typography>
        <div className="FindOrCreateDevice">
          {$selects}
          <AsyncButton
            action={this.findOrCreateDeviceAndPairing(device)}
          >
            Start pairing
          </AsyncButton>
        </div>
      </div>
    );
  }

  renderSelects () {
    const {deviceInput} = this.state;

    return ['type', 'model'].map((attr)=> {
      const enumName = `Device${_.capitalize(attr)}`;
      const enoom = Enums[enumName];
      const options = enumToOptions(enoom);
      const value = deviceInput[attr];
      return (
        <div
          key={enumName}
          className="FormField"
        >
          <Select
            value={value}
            onChange={(evt)=> {
              deviceInput[attr] = evt.target.value;
              this.setState({deviceInput});
            }}
            label={enumName}
            options={options}
          />
        </div>
      );
    });
  }

  handleCodeChange = (evt)=> {
    evt.preventDefault();
    const code = evt.target.value;
    this.setState({code});
  };

  findOrCreateDeviceAndPairing = (device)=> {
    return ()=> {
      let input;
      if (device) {
        const {localId, model, type} = device;
        input = {localId, model, type};
      } else {
        const localId = this.getLocalId();
        input = {
          ...this.state.deviceInput,
          localId
        };
      }

      return this.mutate({
        mutation: findOrCreateDeviceAndPairing,
        variables: {input}
      });
    };
  };

  unpairDevice = (pairingId)=> {
    return ()=> {
      return this.mutate({
        mutation: unpairDevice,
        variables: {
          pairingId
        }
      });
    };
  }

  pairDevice = async ()=> {
    const {code} = this.state;
    const accountId = session.account.id;
    await this.mutate({
      mutation: pairDevice,
      variables: {code, accountId}
    });
    this.setState({code: ''});
  };

  mutate ({mutation, variables}) {
    const {client} = this.props;
    const localId = this.getLocalId();
    return client.mutate({
      mutation,
      variables,
      refetchQueries: [
        {
          query: getDeviceByLocalId,
          variables: {
            localId
          }
        }
      ]
    });
  }
}

DevicePairingPage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  params: PropTypes.shape({
    localId: PropTypes.string
  })
};

export default withApollo(DevicePairingPage);
