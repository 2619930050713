import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from '@rmwc/button';

import {
  Enums,
  Page
} from '@tripp/shared';
import {listDevices} from './DeviceQueries';

import './Device.scss';

class DeviceListPage extends Component {
  render () {
    return (
      <Page
        className="DeviceListPage"
        query={listDevices}
      >
        {({listDevices: devices})=> {
          if (!devices.length) {
            return 'No devices';
          }

          const $deviceRows = devices.map((device)=> {
            const {id, localId, pairings} = device;
            const $pairings = this.renderPairings(pairings);
            return (
              <tr className="Device" key={id}>
                <td>
                  <Button onClick={this.gotoDevicePage(localId)}>{id}</Button>
                </td>
                <td>
                  {localId}
                </td>
                <td>
                  {$pairings}
                </td>
              </tr>
            );
          });

          return (
            <table>
              <thead>
                <tr>
                  <th>id</th>
                  <th>localId</th>
                  <th>pairings</th>
                </tr>
              </thead>
              <tbody>
                {$deviceRows}
              </tbody>
            </table>
          );
        }}
      </Page>
    );
  }

  renderPairings (pairings) {
    const emails = pairings.filter(({status})=> {
      return (status === Enums.PairingStatus.Paired);
    }).map(({account})=> account.email);

    return (
      <div>
        {pairings.length} pairings{emails.length ? `: ${emails.join(' ')}` : ''}
      </div>
    );
  }

  gotoDevicePage (localId) {
    return (evt)=> {
      const {router} = this.props;
      evt.preventDefault();
      router.go(`/devices/pairing/${localId}`);
    };
  }
}


DeviceListPage.propTypes = {
  router: PropTypes.object.isRequired
};

export default DeviceListPage;
