import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Typography} from '@rmwc/typography';
import {List, ListItem} from '@rmwc/list';
import {Button} from '@rmwc/button';

import {listCompanies} from './CompanyQueries';

import './Company.scss';

class CompanyListPage extends Component {
  state = {
    companies: [],
    loading: true
  };

  async componentDidMount () {
    if (this.state.loading) {
      const companies = await this.listCompanies();
      this.setState({
        loading: false,
        companies
      });
    }
  }

  render () {
    const {router} = this.props;
    const {loading, companies} = this.state;
    if (loading) {
      return <div>Loading</div>;
    }
    return (
      <div className="Page CompanyListPage">
        <Button onClick={()=> { router.go('/companies/create'); }}>
          New Company
        </Button>
        <br />
        <Typography use="headline6">Companies</Typography>
        <List>
          {
            companies.map((el, id)=> (
              <ListItem key={id} onClick={()=> { router.go(`/companies/${el.id}`); }}>
                {el.name}
              </ListItem>
            ))
          }
        </List>
      </div>
    );
  }

  listCompanies = async ()=> {
    const {client} = this.props;

    const result = await client.query({
      query: listCompanies
    });

    return result.data.listCompanies;
  }
}

CompanyListPage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired
};

export default withApollo(CompanyListPage);
