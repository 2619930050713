import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {withApollo} from 'react-apollo';

import {Typography} from '@rmwc/typography';
import {Button} from '@rmwc/button';
import {Elevation} from '@rmwc/elevation';

import {getCatalogEntry} from './CatalogQueries';

import './Catalog.scss';

class CatalogEntryPage extends Component {
  state = {
    loading: true
  };

  async componentDidMount () {
    const {loading} = this.state;
    if (loading) {
      const {id} = this.props.params;
      const catalogEntry = await this.getCatalogEntry(id);
      console.log(catalogEntry, id);
      this.setState({
        catalogEntry,
        loading: false
      });
    }
  }

  render () {
    const {router, params} = this.props;
    const {loading} = this.state;
    if (loading) {
      return <h1>Loading</h1>;
    }
    const {
      localization: {
        name,
        description,
        footerText
      },
      image
    } = this.state.catalogEntry;
    let url;
    if (image) {
      const thumbnail = image.sizes.find(({type})=> {
        return type === 'SquareSmall';
      });
      if (thumbnail) {
        url = thumbnail.url;
      }
    }

    return (
      <div className="Page CatalogEntryPage Centered">
        <Elevation z={3} className="Centered">
          <Button onClick={()=> { router.go('/catalog'); }}>
            back
          </Button>
          <Button onClick={()=> { router.go(`/catalog/${params.id}/edit`); }}>
            edit
          </Button>
          <br />
          {url && <img src={url} />}
          <Typography use="headline3">{name}</Typography>
          <br />
          <p>{description}</p>
          <p>{footerText}</p>
          <br />
        </Elevation>
      </div>
    );
  }

  getCatalogEntry = async (id)=> {
    const {client} = this.props;
    const variables = {id, locale: 'en'};

    const result = await client.query({
      query: getCatalogEntry,
      variables
    });

    return result.data.getCatalogEntry;
  }
}

CatalogEntryPage.propTypes = {
  router: PropTypes.object.isRequired,
  client: PropTypes.object.isRequired,
  params: PropTypes.shape({
    id: PropTypes.string.isRequired
  })
};

export default withApollo(CatalogEntryPage);
